import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { setUser } from '../actions/users';
import UserNew from '../components/UserNew';

const mapDispatchToProps = {
  setUser,
  navigate: push,
};

export default connect(() => ({}), mapDispatchToProps)(UserNew);
