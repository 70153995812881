import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import Api from '../../utilities/Api';

import {
  REQUEST_DEALERSHIP_DEALER_USERS_FETCH,
  REQUEST_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
} from '../../constants/dealership_dealer_users';

import { BEGIN_FETCH, END_FETCH, END_INITIAL_LOAD } from '../../constants/status';

function* fetchDealershipDealerUsers() {
  yield put({ type: BEGIN_FETCH });
  try {
    const response = yield call(Api.getAuthenticated, '/consultants');
    yield put({ type: REQUEST_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: error.response.data.error });
    yield put({ type: END_INITIAL_LOAD });
    yield put(push('/login'));
  }
  yield put({ type: END_FETCH });
}

export default function* watchForFetchRequestDealershipDealerUsers() {
  while (true) {
    yield take(REQUEST_DEALERSHIP_DEALER_USERS_FETCH);
    yield call(fetchDealershipDealerUsers);
  }
}
