import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Alert, Card, CardBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { faEnvelopeOpenText, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '../shared/CustomIcons';
import { requestCreate } from '../UserForm/FormApiRequests';
import { getCountries } from '../shared/Countries';

const DemonstrationNew = ({ setUser, navigate }) => {
  const countries = getCountries();
  const defaultCountry = countries.find(c => c.name.indexOf('America') >= 0) || { id: null };

  const defaultObject = {
    dealer_demonstration: true,
    demo_account: false,
    country_id: defaultCountry.id,
  };

  const [formObject, setFormObject] = useState(defaultObject);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  function onChange(value, attribute) {
    setFormObject({
      ...formObject,
      [attribute]: value,
    });
  }

  async function onSave(event) {
    event.preventDefault();
    try {
      setFormErrorMessage(null);

      const responseObject = await requestCreate({
        ...formObject,
        country_id: formObject.country_id || defaultCountry.id,
      });
      setUser(responseObject.data);
      navigate(`/demonstrations/${responseObject.data.id}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      setFormErrorMessage(e.response.data.errors[0]);
      window.scrollTo(0, 0);
    }
  }
  return (
    <section className="user-form">
      <NavLink exact to="/demonstrations" className="d-block mb-4">
        <FontAwesomeIcon icon={faLongArrowLeft} />
        {' '}
        Return to all Demonstrations
      </NavLink>
      <h1>
        New Demonstration
      </h1>
      {formErrorMessage && (
        <Alert color="danger" className="user-form--error">
          {formErrorMessage}
        </Alert>
      )}
      <form onSubmit={onSave}>
        <Card className="shadow user-form--contact-information mb-4">
          <CardBody>
            <h3>
              <FontAwesomeIcon icon={faTv} className="mr-2" />
              Demonstration Information
            </h3>
            <div>
              <InputGroup className="mb-2">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Name</InputGroupText>
                </InputGroupAddon>
                <Input placeholder='"Corn & Soybean Rotation Example"' value={formObject.name} onChange={e => onChange(e.target.value, 'name')} />
              </InputGroup>
            </div>
          </CardBody>
        </Card>
        <div className="actions">
          <Button color="primary" type="submit">
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="mr-2" />
            <b>Create</b>
            {' '}
            Demonstration
          </Button>
          <NavLink exact to="/demonstrations" className="btn btn-secondary ml-3">
            Cancel
          </NavLink>
        </div>
      </form>
    </section>
  );
};

DemonstrationNew.propTypes = {
  setUser: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default DemonstrationNew;
