import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setUser } from '../actions/users';
import Edit from '../components/UserEdit';
import { getUserByID } from '../reducers/users';

const mapDispatchToProps = {
  setUser,
  navigate: push,
};

function mapStateToProps(state, props) {
  const userId = parseInt(props.match.params.id, 10);
  return {
    user: getUserByID(state, userId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
