import {
  REQUEST_DEALER_USER_FETCH,
  DEALER_USER_REQUEST_RESET_ERRORS,
} from '../constants/dealer_user';


export function fetchDealerUser(id) {
  return { type: REQUEST_DEALER_USER_FETCH, id };
}

export function resetDealerUserErrors() {
  return { type: DEALER_USER_REQUEST_RESET_ERRORS };
}
