import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { faLongArrowLeft } from './shared/CustomIcons';

const UserNotFound = () => (
  <section className="user empty">
    <Row noGutters className="justify-content-between py-4">
      <Col xs="12">
        <h1>We couldn&apos;t find that user</h1>
      </Col>
      <NavLink exact to="/" className="mt-4 btn btn-primary">
        <FontAwesomeIcon icon={faLongArrowLeft} />
        {' '}
        Return to all users
      </NavLink>
    </Row>
  </section>
);


export default UserNotFound;
