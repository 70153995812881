import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const UserListItemDate = ({ date }) => {
  let dateString = 'Never';
  if (date) {
    dateString = moment(date).calendar(null, { sameElse: 'MMMM D, YYYY' });
  }
  return (
    <time>
      {dateString}
    </time>
  );
};

UserListItemDate.propTypes = {
  date: PropTypes.string,
};

UserListItemDate.defaultProps = {
  date: undefined,
};

export default UserListItemDate;
