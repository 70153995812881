import { all, fork } from 'redux-saga/effects';

import dealerUser from './dealer_user';
import login from './login';
import resetPassword from './reset_password';
import logout from './logout';
import users from './users';
import dealership from './dealership';
import dealershipDealerUsers from './dealership_dealer_users';

export default function* rootSaga() {
  yield all([
    fork(dealerUser),
    fork(login),
    fork(logout),
    fork(users),
    fork(resetPassword),
    fork(dealership),
    fork(dealershipDealerUsers),
  ]);
}
