import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const AccessMetrics = ({ user }) => {
  let lastVisitDate = 'Never';
  if (user.current_sign_in_at) {
    lastVisitDate = moment(user.current_sign_in_at).calendar(null, { sameElse: 'MMMM D, YYYY' });
  }

  let signUpDate = 'Never';
  if (user.software_sign_up_date) {
    signUpDate = moment(user.software_sign_up_date).calendar(null, { sameElse: 'MMMM D, YYYY' });
  }

  return (
    <div className="user--access-metrics-container">
      <div className="user--access-metrics">
        <div>
          <b>Last seen</b>
          {' '}
          <time>{lastVisitDate}</time>
        </div>
        <div>
          <b>Signed up</b>
          {' '}
          <time>{signUpDate}</time>
        </div>
        <div>
          <b>Visited</b>
          {' '}
          {user.sign_in_count}
        </div>
      </div>
    </div>
  );
};

AccessMetrics.propTypes = {
  user: PropTypes.shape({
    current_sign_in_at: PropTypes.string,
    sign_in_count: PropTypes.number,
    software_sign_up_date: PropTypes.string,
  }).isRequired,
};

export default AccessMetrics;
