import PropTypes from 'prop-types';
import React from 'react';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCountries } from '../shared/Countries';

const ContactDetails = ({ user }) => {
  const countries = getCountries();
  const country = countries.find(c => c.id === user.country_id) || { name: '' };

  return (
    <Card className="shadow user--contact-details mb-4">
      <CardBody>
        <Row>
          {(user.street_address || '').length > 0 && (
            <Col className="mr-4">
              <h3>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                Address
              </h3>
              <address>
                {user.street_address}
                <br />
                {user.city}
                ,
                {' '}
                {user.state}
                {' '}
                {user.zip}
                <br />
                {country.name}
              </address>
            </Col>
          )}

          <Col>
            <h3>
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              Contact Information
            </h3>
            <address className="user--contact-info">
              Email:
              {' '}
              <a href={`mailto:${user.email}`}>{user.email}</a>
              <br />

              {user.cell_phone && (
                <>
                  TEL:
                  {' '}
                  <a href={`tel:${user.cell_phone}`}>{user.cell_phone}</a>
                </>
              )}
            </address>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ContactDetails.propTypes = {
  user: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    street_address: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    cell_phone: PropTypes.string,
    country_id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default ContactDetails;
