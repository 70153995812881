import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faDoorOpen, faKey, faUsers, faTv, faSitemap } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
} from 'reactstrap';
import Support from './Navigation/Support';

import logo from '../assets/management-logo.png';

function initialsFromString(string) {
  return string.split(' ').map(word => (word.length > 0 ? word[0] : '')).join('').toUpperCase();
}

const Navigation = (props) => {
  const [accountPopupOpen, setAccountPopupOpen] = useState(false);
  const goToUsers = () => props.navigate('/');
  const goToManageDealership = () => props.navigate('/dealership');
  const goToDemonstrations = () => props.navigate('/demonstrations');

  return (
    <header className="d-flex flex-row justify-content-between">
      <img src={logo} alt="Harvest Profit" height="30" />
      <nav>
        <div className="header--left">
          <a className="text-dark" href="https://docs.harvestprofit.com" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="mr-2" icon={faGraduationCap} />
            Resources
          </a>
          <Button color="link" className="text-dark ml-5" id="dealermanage" onClick={() => goToUsers()}>
            <FontAwesomeIcon className="mr-2" icon={faUsers} />
            Users
          </Button>

          { props.dealer_user.admin_access && (
            <Button color="link" className="text-dark ml-5" id="dealermanage" onClick={() => goToDemonstrations()}>
              <FontAwesomeIcon className="mr-2" icon={faTv} />
              Demonstrations
            </Button>
          )}

          { props.dealer_user.admin_access && (
            <Button color="link" className="text-dark ml-5" id="dealermanage" onClick={() => goToManageDealership()}>
              <FontAwesomeIcon className="mr-2" icon={faSitemap} />
              Dealership
            </Button>
          )}
        </div>
        <div className="header--right">
          <Support
            dealerUserName={props.dealer_user.display_name}
            dealerUserID={props.dealer_user.id}
            dealerUserEmail={props.dealer_user.email}
          />
        </div>
      </nav>
      <Dropdown className="account" isOpen={accountPopupOpen} toggle={() => setAccountPopupOpen(!accountPopupOpen)}>
        <DropdownToggle caret>
          <div className="account-icon account-icon--initials">
            {initialsFromString(props.dealer_user.display_name)}
          </div>
        </DropdownToggle>
        <DropdownMenu right className="with-arrow">
          <DropdownItem header className="d-flex flex-row account-details">
            <div className="account-icon account-icon--initials">
              {initialsFromString(props.dealer_user.display_name)}
            </div>
            <div>
              <h5>{props.dealer_user.display_name}</h5>
              <h6>{props.dealer_user.email}</h6>
              <h6>{props.dealer_user.admin_access ? 'Admin Dealer User' : ''}</h6>
            </div>
          </DropdownItem>
          <DropdownItem header className="d-flex flex-row account-details">
            <div>
              <h5>Dealership:</h5>
              <h6>{props.dealer_user.company_name }</h6>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem toggle onClick={() => props.navigate('change-password')}>
            <FontAwesomeIcon className="mr-2" icon={faKey} />
            Change Password
          </DropdownItem>
          <DropdownItem toggle={false} onClick={props.logout}>
            <FontAwesomeIcon className="mr-2" icon={faDoorOpen} />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </header>
  );
};

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  dealer_user: PropTypes.shape({
    id: PropTypes.number,
    display_name: PropTypes.string,
    email: PropTypes.string,
    admin_access: PropTypes.bool,
    company_name: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Navigation;
