import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchDealerUser } from '../actions/dealer_user';

import Routes from './index/index';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchDealerUser,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    dealer_user: state.dealerUser.dealer_user,
    status: state.status,
  };
}

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(Routes));
