import React from 'react';
import { NavLink } from 'react-router-dom';

import Layout from './Layout';

const PasswordResetSent = () => (
  <Layout title="Reset Password Link Sent">
    <p>Check your email, we sent you a link to reset your password.</p>
    <p>
      If you are having issues, please
      {' '}
      <a href="mailto:dealers@harvestprofit.com">contact us</a>
    </p>
    <NavLink exact to="/login" className="btn btn-primary btn-block">Return to sign in</NavLink>
  </Layout>
);

export default PasswordResetSent;
