
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';

const AccountDetails = ({ userID, accountMetrics }) => (
  <Card className="shadow user--account-metrics mb-4">
    <CardBody>
      <h3>Account Details</h3>
      <div>
        <b>Connected to: </b>
        {accountMetrics.connected_services.length < 1 ? 'No 3rd party services' : accountMetrics.connected_services.join(', ')}
      </div>
      <div>
        <b>Account Size: </b>
        <span id={`user-${userID}-account-size`} style={{ textTransform: 'capitalize', textDecoration: 'underline', textDecorationStyle: 'dotted' }}>{accountMetrics.account_size}</span>


        <UncontrolledTooltip placement="top" target={`user-${userID}-account-size`} boundariesElement="window">
          <span className="d-flex flex-column align-items-start justify-content-start text-left">
            • Small: &lt; 20 fields
            <br />
            • Medium: 20 to 50 fields
            <br />
            • Large: &gt; 50 fields
          </span>
        </UncontrolledTooltip>
      </div>

      <div>
        <b>Usage: </b>
        {accountMetrics.feature_usage.length < 1 ? 'Core Features Only' : accountMetrics.feature_usage.join(', ')}
      </div>
    </CardBody>
  </Card>
);

AccountDetails.propTypes = {
  userID: PropTypes.number.isRequired,
  accountMetrics: PropTypes.shape({
    progress: PropTypes.number,
    completed_onboarding: PropTypes.bool,
    connected_services: PropTypes.arrayOf(PropTypes.string),
    feature_usage: PropTypes.arrayOf(PropTypes.string),
    added_overhead_inputs: PropTypes.bool,
    added_crop_plan: PropTypes.bool,
    added_applications: PropTypes.bool,
    last_updated: PropTypes.string,
    account_size: PropTypes.string,
  }).isRequired,
};

export default AccountDetails;
