export const REQUEST_USERS_FETCH = 'REQUEST_USERS_FETCH';
export const REQUEST_USERS_FETCH_SUCCESS = 'REQUEST_USERS_FETCH_SUCCESS';
export const REQUEST_USERS_FETCH_FAILURE = 'REQUEST_USERS_FETCH_FAILURE';
export const REQUEST_USER_FETCH = 'REQUEST_USER_FETCH';
export const REQUEST_USER_FETCH_SUCCESS = 'REQUEST_USER_FETCH_SUCCESS';
export const REQUEST_USER_FETCH_FAILURE = 'REQUEST_USER_FETCH_FAILURE';

export const REQUEST_USERS_RESET = 'REQUEST_USERS_RESET';

export const REQUEST_LOGIN_AS_USER = 'REQUEST_LOGIN_AS_USER';
export const REQUEST_LOGIN_AS_USER_SUCCESS = 'REQUEST_LOGIN_AS_USER_SUCCESS';

export const SET_USER = 'SET_USER';
