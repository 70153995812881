/* eslint-disable jsx-a11y/no-autofocus */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import Layout from './Layout';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.updateEmail = this.updateEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateEmail(event) {
    const email = event.target.value;
    this.setState({ email });
  }

  updatePassword(event) {
    const password = event.target.value;
    this.setState({ password });
  }

  submit(event) {
    event.preventDefault();
    this.props.login(
      this.state.email,
      this.state.password,
    );
  }

  render() {
    const { message } = this.props;
    return (
      <Layout title="Sign In">
        <Form onSubmit={this.submit}>
          {message && (
            <p className="my-2 text-danger">{message}</p>
          )}
          <InputGroup className="inline-group-text">
            <input
              type="email"
              name="email"
              id="email"
              autoFocus
              className="form-control"
              onChange={this.updateEmail}
              placeholder="Email"
              value={this.state.email}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup className="inline-group-text">
            <Input
              type="password"
              name="password"
              id="password"
              onChange={this.updatePassword}
              placeholder="Password"
              value={this.state.password}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FontAwesomeIcon icon={faKey} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <Button block color="primary" type="submit">Sign In</Button>
          <NavLink exact to="/password-reset" className="d-block text-center">Forgot Password?</NavLink>
        </Form>
      </Layout>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default Login;
