import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactInformation = ({ formObject, onChange }) => (
  <Card className="shadow user-form--contact-information mb-4">
    <CardBody>
      <h3>
        <FontAwesomeIcon icon={faPhone} className="mr-2" />
        Contact Information
      </h3>
      <div>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Name</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="John Doe" value={formObject.name} onChange={e => onChange(e.target.value, 'name')} />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Phone</InputGroupText>
          </InputGroupAddon>
          <Input type="phone" value={formObject.cell_phone} onChange={e => onChange(e.target.value, 'cell_phone')} />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Email</InputGroupText>
          </InputGroupAddon>
          <Input type="email" placeholder="John@deere.com" value={formObject.email} onChange={e => onChange(e.target.value, 'email')} disabled={!!formObject.id} />
        </InputGroup>
        {formObject.id && (
          <small>*Please contact support if you need to change this user’s email</small>
        )}
      </div>
    </CardBody>
  </Card>
);

ContactInformation.propTypes = {
  formObject: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    cell_phone: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContactInformation;
