import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactInformation = ({ formObject, onChange, newOrUpdate }) => (
  <Card className="shadow user-form--contact-information mb-4">
    <CardBody className="mb-3">
      <h3>
        <FontAwesomeIcon icon={faPhone} className="mr-2" />
        Contact Information
      </h3>
      <div>
        { newOrUpdate === 'new' && (
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ width: 100 }}>Email</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="John@harvestprofit.com" value={formObject.email} onChange={e => onChange(e.target.value, 'email')} />
        </InputGroup>
        )}
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ width: 100 }}>First Name</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="John" value={formObject.first_name} onChange={e => onChange(e.target.value, 'first_name')} />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ width: 100 }}>Last Name</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="Smith" value={formObject.last_name} onChange={e => onChange(e.target.value, 'last_name')} />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ width: 100 }}>Phone</InputGroupText>
          </InputGroupAddon>
          <Input placeholder="5556667777" type="phone" value={formObject.phone} onChange={e => onChange(e.target.value, 'phone')} />
        </InputGroup>
      </div>
    </CardBody>
  </Card>
);

ContactInformation.propTypes = {
  formObject: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    admin_access: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  newOrUpdate: PropTypes.string.isRequired,
};

export default ContactInformation;
