import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'reactstrap';
import DealerUserListItem from './DealershipUsersList/DealerUserListItem';

// eslint-disable-next-line max-len
const DealershipUsersList = ({ dealerUsers, requestDeleteDealerUser, currentDealerUser, fetchSingleDealershipDealerUser }) => (
  <Table hover responsive>
    <thead className="card-header">
      <tr>
        <th>
          User
        </th>
        <th>
          Sign In Access
        </th>
        <th>
          Admin Access
        </th>
        <th />
      </tr>
    </thead>
    <tbody className="card-body">
      {dealerUsers.map(du => (
        <DealerUserListItem
          key={du.id}
          dealerUser={du}
          requestDeleteDealerUser={requestDeleteDealerUser}
          currentDealerUser={currentDealerUser}
          fetchSingleDealershipDealerUser={fetchSingleDealershipDealerUser}
        />
      ))}
    </tbody>
  </Table>
);

DealershipUsersList.propTypes = {
  currentDealerUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  requestDeleteDealerUser: PropTypes.func.isRequired,
  dealerUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    main_account: PropTypes.bool.isRequired,
  })).isRequired,
  fetchSingleDealershipDealerUser: PropTypes.func.isRequired,
};

export default DealershipUsersList;
