import { connect } from 'react-redux';

import Loading from '../components/Loading';

function mapStateToProps(state) {
  return {
    status: state.status,
  };
}

export default connect(mapStateToProps, null)(Loading);
