import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import DealerUserPermission from './DealerUserPermission';
import Api from '../../../../utilities/Api';

class DealerUserPermissions extends Component {
  // eslint-disable-next-line class-methods-use-this
  constructor(props) {
    super(props);
    this.togglePermission = this.togglePermission.bind(this);
    this.state = { dealer_user_permissions: [] };
  }

  componentDidMount() {
    this.fetchPermissions();
  }

  async fetchPermissions() {
    try {
      const response = await Api.getAuthenticated('/dealer_user_permissions', { dealer_user_id: this.props.dealer_user.id });
      this.setState({ dealer_user_permissions: response.data });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async grantAll(e) {
    e.preventDefault();
    const response = await Api.postAuthenticated('/dealer_user_permissions/grant_all', { dealer_user_id: this.props.dealer_user.id });
    this.setState({ dealer_user_permissions: response.data });
  }

  async revokeAll(e) {
    e.preventDefault();
    const response = await Api.postAuthenticated('/dealer_user_permissions/revoke_all', { dealer_user_id: this.props.dealer_user.id });
    this.setState({ dealer_user_permissions: response.data });
  }


  async makeToggleRequestAndSetState(id) {
    const response = await Api.postAuthenticated(`/dealer_user_permissions/${id}/toggle`);
    const otherPermissions = this.state.dealer_user_permissions.filter(perm => perm.id !== id);
    const newPermissions = [response.data, ...otherPermissions];
    this.setState({ dealer_user_permissions: newPermissions });
  }

  togglePermission(id, e) {
    e.preventDefault();
    this.makeToggleRequestAndSetState(id);
  }

  sortedDealerUserPermissions() {
    return _.sortBy(this.state.dealer_user_permissions, 'user_name');
  }

  render() {
    if (_.isEmpty(this.state.dealer_user_permissions)) {
      return (
        <div style={{ minHeight: '60vh', opacity: 0.6 }} className="d-flex flex-column align-items-center justify-content-center">
          <div style={{ fontSize: 50 }}>
            <FontAwesomeIcon pulse icon={faSpinner} />
          </div>
          Fetching Permissions
        </div>
      );
    }

    const { dealer_user: dealerUser } = this.props;

    return (
      <section className="user mb-3">
        <h1>
          {`${dealerUser.display_name} Permissions`}
          <Button className="btn btn-secondary float-right" onClick={() => this.props.toggleModal(false)}>
            Go Back
          </Button>
        </h1>
        <div className="card mt-3 shadow-sm" style={{ minHeight: 150, maxHeight: 400, overflow: 'auto', display: 'block' }}>
          <Table>
            <thead className="card-header">
              <tr>
                <th>
                  HP User Name
                </th>
                <th>
                  Access
                </th>
                <th>
                  Toggle
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="card-body">
              {this.sortedDealerUserPermissions().map(dup => (
                <DealerUserPermission
                  dealerUserPermission={dup}
                  togglePermission={this.togglePermission}
                />
              ))}
            </tbody>
          </Table>
        </div>
        <div className="mt-5">
          <button type="submit" className="btn btn-primary border-0" onClick={e => this.grantAll(e)}>
            Grant Access For All
          </button>
          <button type="submit" className="btn btn-danger border-0 ml-3" onClick={e => this.revokeAll(e)}>
            Revoke All
          </button>
        </div>
      </section>
    );
  }
}

DealerUserPermissions.propTypes = {
  dealer_user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default DealerUserPermissions;
