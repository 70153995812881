import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { faUser, faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// eslint-disable-next-line react/prefer-stateless-function
class PermissionOptions extends Component {
  render() {
    const { formObject } = this.props;
    const { admin_access: adminAccess } = formObject;
    return (
      <Card className="shadow user-form--contact-information mb-4">
        <CardBody className="mb-3">
          <h3>
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            Dealer User Permissions
          </h3>
          <Row className="mt-4">
            <Col>
              <h4 className="mb-3">Admin Access</h4>
              <p className="mb-4">
                With Admin Access, the Dealer User can create new dealer users,
                change dealer users permissions, and add new Harvest Profit Users.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color="secondary" onClick={() => this.props.onChange(!adminAccess, 'admin_access')}>
                <FontAwesomeIcon icon={adminAccess ? faBan : faCheckCircle} className="mr-2" />
                <b>{adminAccess ? 'Revoke' : 'Grant'}</b>
                {' '}
                Admin Access
              </Button>
              <p className="mt-2" style={{ fontSize: 10 }}>
                {adminAccess ? 'Admin Access is granted' : 'Admin Access is revoked'}
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

PermissionOptions.propTypes = {
  formObject: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    admin_access: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PermissionOptions;
