import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DealershipUserUpdate from '../../components/Dealership/DealershipUsersList/DealerUserUpdate/DealerUserUpdate';
import { resetDealershipDealerUserErrors, updateDealershipDealerUser } from '../../actions/dealership_dealer_users';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    resetDealershipDealerUserErrors,
    updateDealershipDealerUser,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    error: state.dealershipDealerUsers.error,
    updating: state.dealershipDealerUsers.updating,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealershipUserUpdate);
