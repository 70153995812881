import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { faCloud, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '../shared/CustomIcons';
import { requestUpdate } from '../UserForm/FormApiRequests';
import DemonstrationNotFound from './DemonstrationNotFound';

const DemonstrationEdit = ({ demonstration, setDemonstration, navigate }) => {
  const [formObject, setFormObject] = useState({});

  useEffect(() => {
    setFormObject(demonstration);
  }, demonstration.id);

  function onChange(value, attribute) {
    setFormObject({
      ...formObject,
      [attribute]: value,
    });
  }

  async function onSave(event) {
    event.preventDefault();
    try {
      const responseObject = await requestUpdate(formObject);
      setDemonstration(responseObject.data);
      navigate(`/demonstrations/${responseObject.data.id}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }

  if (isEmpty(demonstration)) return <DemonstrationNotFound />;

  return (
    <section className="user-form">
      <NavLink exact to="/" className="d-block mb-4">
        <FontAwesomeIcon icon={faLongArrowLeft} />
        {' '}
        Return to all Demonstrations
      </NavLink>
      <h1>
        {'Editing '}
        {(demonstration.name || 'User').split(' ')[0]}
      </h1>
      <form onSubmit={onSave}>
        <Card className="shadow user-form--contact-information mb-4">
          <CardBody>
            <h3>
              <FontAwesomeIcon icon={faTv} className="mr-2" />
              Demonstration Information
            </h3>
            <div>
              <InputGroup className="mb-2">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Name</InputGroupText>
                </InputGroupAddon>
                <Input placeholder='"Corn & Soybean Rotation Example"' value={formObject.name} onChange={e => onChange(e.target.value, 'name')} />
              </InputGroup>
            </div>
          </CardBody>
        </Card>
        <div className="actions">
          <Button color="primary" type="submit">
            <FontAwesomeIcon icon={faCloud} className="mr-2" />
            <b>Save</b>
            {' '}
            changes
          </Button>
          <NavLink exact to={`/users/${demonstration.id}`} className="btn btn-secondary ml-3">
            Cancel
          </NavLink>
        </div>
        <hr />
      </form>
    </section>
  );
};

DemonstrationEdit.propTypes = {
  demonstration: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    demo_account: PropTypes.bool,
  }),
  setDemonstration: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

DemonstrationEdit.defaultProps = {
  demonstration: {},
};

export default DemonstrationEdit;
