import {
  REQUEST_USERS_FETCH_SUCCESS,
  REQUEST_USERS_FETCH_FAILURE,
  REQUEST_USER_FETCH_SUCCESS,
  REQUEST_USER_FETCH_FAILURE,
  REQUEST_USERS_RESET,
  SET_USER,
} from '../constants/users';

export function getUserByID(state, id) {
  return state.users.users[id];
}

export function getUsers(state) {
  return Object.values(state.users.users);
}

export const initialState = {
  users: {},
  error: false,
  errorMessage: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_USERS_RESET:
      return initialState;
    case SET_USER:
    case REQUEST_USER_FETCH_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: action.payload,
        },
      };
    case REQUEST_USER_FETCH_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      };
    case REQUEST_USERS_FETCH_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload.reduce((agg, user) => ({ ...agg, [user.id]: user }), {}),
        },
      };
    case REQUEST_USERS_FETCH_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
