import { take, fork, call, put } from 'redux-saga/effects';

import {
  REQUEST_USER_FETCH,
  REQUEST_USER_FETCH_SUCCESS,
  REQUEST_USER_FETCH_FAILURE,
} from '../../constants/users';

import {
  BEGIN_FETCH,
  END_FETCH,
  END_INITIAL_LOAD,
} from '../../constants/status';

import Api from '../../utilities/Api';

/**
 * Fetches a User. Hits the API, and dispatches
 * additional redux actions based on the response.
 */
function* fetchUser(id) {
  try {
    yield put({ type: BEGIN_FETCH });
    const response = yield call(Api.getAuthenticated, `/users/${id}`);
    yield put({ type: REQUEST_USER_FETCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: REQUEST_USER_FETCH_FAILURE, payload: error.message });
  }
  yield put({ type: END_FETCH });
  yield put({ type: END_INITIAL_LOAD });
}

export default function* watchForFetchUser() {
  while (true) {
    const action = yield take(REQUEST_USER_FETCH);
    yield fork(fetchUser, action.payload);
  }
}
