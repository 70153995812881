import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { setUser } from '../../actions/users';
import DemonstrationNew from '../../components/Demonstrations/DemonstrationNew';

const mapDispatchToProps = {
  setUser,
  navigate: push,
};

export default connect(() => ({}), mapDispatchToProps)(DemonstrationNew);
