import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import {
  DEALERSHIP_DEALER_USER_REQUEST_CREATE,
  DEALERSHIP_DEALER_USER_CREATED,
  DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
} from '../../constants/dealership_dealer_users';

import Api from '../../utilities/Api';

export function* createDealerUser(dealerUser = {}) {
  yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS });
  if (dealerUser === {}) return;
  try {
    const response = yield call(
      Api.postAuthenticated,
      '/consultants',
      { ...dealerUser },
    );
    yield put({
      type: DEALERSHIP_DEALER_USER_CREATED,
      payload: response.data,
    });
    yield put(push('/dealership'));
  } catch (error) {
    if (error.response && error.response.data) {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: error.response.data.error });
    } else {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: 'Something went wrong. Please try again' });
    }
  }
}

export default function* watchForCreateDealershipDealerUser() {
  while (true) {
    const action = yield take(DEALERSHIP_DEALER_USER_REQUEST_CREATE);
    yield call(createDealerUser, action.payload);
  }
}
