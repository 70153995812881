import { call, put, take } from 'redux-saga/effects';

import Api from '../../utilities/Api';

import {
  REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH,
  REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
} from '../../constants/dealership_dealer_users';

import { BEGIN_FETCH, END_FETCH } from '../../constants/status';

function* fetchSingleDealershipDealerUser(id) {
  yield put({ type: BEGIN_FETCH });
  try {
    const response = yield call(Api.getAuthenticated, `/consultants/${id}`);
    yield put({ type: REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS,
      payload: response.data });
  } catch (error) {
    yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: error.response.data.error });
  }
  yield put({ type: END_FETCH });
}

export default function* watchForFetchRequestSingleDealershipDealerUsers() {
  while (true) {
    const action = yield take(REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH);
    yield call(fetchSingleDealershipDealerUser, action.id);
  }
}
