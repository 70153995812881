import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import User from '../components/User';
import { getUserByID } from '../reducers/users';
import { requestSingleUser } from '../actions/users';

const mapDispatchToProps = {
  requestSingleUser,
  navigate: push,
};

function mapStateToProps(state, props) {
  const userId = parseInt(props.match.params.id, 10);

  return {
    dealer_user: state.dealerUser.dealer_user,
    user: getUserByID(state, userId),
    fetching: state.status.fetching > 0,
    userId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
