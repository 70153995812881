import { call, fork, put, take } from 'redux-saga/effects';

import {
  REQUEST_LOGIN_AS_USER,
  REQUEST_LOGIN_AS_USER_SUCCESS,
} from '../../constants/users';
import Api from '../../utilities/Api';

/**
 * Fetches Users for the given page number. Hits the API, and dispatches
 * additional redux actions based on the response.
 */
function* loginAsUser(id) {
  try {
    const response = yield call(Api.postAuthenticated, `/users/${id}/login`);
    const { url } = response.data;
    window.location.assign(url);
    yield put({ type: REQUEST_LOGIN_AS_USER_SUCCESS });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchForLoginAsUser() {
  while (true) {
    const action = yield take(REQUEST_LOGIN_AS_USER);
    yield fork(loginAsUser, action.payload);
  }
}
