import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import DealershipUserNew from '../../components/Dealership/DealershipUserNew';
import { reqestCreateDealershipDealerUser, resetDealershipDealerUserErrors } from '../../actions/dealership_dealer_users';

const mapDispatchToProps = {
  navigate: push,
  reqestCreateDealershipDealerUser,
  resetDealershipDealerUserErrors,
};

function mapStateToProps(state) {
  return {
    dealership: state.dealership.dealership,
    error: state.dealershipDealerUsers.error,
    dealer_user: state.dealerUser.dealer_user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealershipUserNew);
