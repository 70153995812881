import { all, fork } from 'redux-saga/effects';

import watchForFetchUser from './users/fetch_single';
import watchForFetchUsers from './users/fetch';
import watchForLoginAsUser from './users/login';

export default function* users() {
  yield all([
    fork(watchForFetchUser),
    fork(watchForFetchUsers),
    fork(watchForLoginAsUser),
  ]);
}
