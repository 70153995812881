import Api from '../../utilities/Api';

let countries = null;

export async function fetchCountries() {
  try {
    const response = await Api.getAuthenticated('/countries');
    countries = response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

fetchCountries();

export function getCountries() {
  if (countries) return countries;
  fetchCountries();
  return [];
}
