import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Input, InputGroup } from 'reactstrap';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCountries } from '../shared/Countries';

const AddressInformation = ({ formObject, onChange }) => {
  const countries = getCountries();
  const defaultCountry = countries.find(c => c.name.indexOf('America') >= 0) || { id: null };
  return (
    <Card className="shadow user-form--address-information mb-4">
      <CardBody>
        <h3>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
          Address
        </h3>
        <div>
          <InputGroup className="mb-2">
            <Input placeholder="Address" value={formObject.street_address} onChange={e => onChange(e.target.value, 'street_address')} />
          </InputGroup>
          <div className="mb-2 d-flex flex-row">
            <Input className="user-form--city" placeholder="City" value={formObject.city} onChange={e => onChange(e.target.value, 'city')} />
            <Input className="user-form--state" placeholder="State/Prov" value={formObject.state} onChange={e => onChange(e.target.value, 'state')} />
            <Input className="user-form--zip" placeholder="Zip" value={formObject.zip} onChange={e => onChange(e.target.value, 'zip')} />
          </div>
          <InputGroup className="mb-2">
            <Input
              type="select"
              className="user-form--country"
              autocomplete="country"
              onChange={e => onChange(e.target.value, 'country_id')}
              value={formObject.country_id || defaultCountry.id}
            >
              {(countries || []).map(c => (
                <option value={c.id}>
                  {c.name}
                </option>
              ))}
            </Input>
          </InputGroup>
          <small>
            * We need to collect address information so we can send out
            an onboarding package.
          </small>
        </div>
      </CardBody>
    </Card>
  );
};

AddressInformation.propTypes = {
  formObject: PropTypes.shape({
    id: PropTypes.number,
    country_id: PropTypes.number,
    city: PropTypes.string,
    state: PropTypes.string,
    street_address: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddressInformation;
