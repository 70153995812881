import PropTypes from 'prop-types';
import React from 'react';
import SortableTableHeaderItem from '../../shared/SortableTableHeaderItem';

const UserListHeader = ({ onChangeSortBy, sortBy }) => (
  <tr>
    <th>
      <SortableTableHeaderItem type="string" sortBy="name" onClick={onChangeSortBy} sortRules={sortBy}>
        User
      </SortableTableHeaderItem>
    </th>
    <th>
      <SortableTableHeaderItem type="generic" sortBy="activity" onClick={onChangeSortBy} sortRules={sortBy}>
        Activity
      </SortableTableHeaderItem>
    </th>
    {/* <th>
      <SortableTableHeaderItem
        type="number"
        sortBy="progress"
        onClick={onChangeSortBy}
        sortRules={sortBy}
      >
        Progress
      </SortableTableHeaderItem>
    </th> */}
    <th>
      <SortableTableHeaderItem type="generic" sortBy="status" onClick={onChangeSortBy} sortRules={sortBy}>
        Status
      </SortableTableHeaderItem>
    </th>
  </tr>
);

UserListHeader.propTypes = {
  sortBy: PropTypes.shape({}).isRequired,
  onChangeSortBy: PropTypes.func.isRequired,
};

export default UserListHeader;
