import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Alert } from 'reactstrap';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from './shared/CustomIcons';
import ContactInformation from './UserForm/ContactInformation';
import AddressInformation from './UserForm/AddressInformation';
import SubscriptionManagement from '../containers/UserSubscriptionManagement';
import { requestCreate } from './UserForm/FormApiRequests';
import { getCountries } from './shared/Countries';

const UserEdit = ({ setUser, navigate }) => {
  const countries = getCountries();
  const defaultCountry = countries.find(c => c.name.indexOf('America') >= 0) || { id: null };

  const defaultObject = {
    dealer_demonstration: false,
    demo_account: true,
    country_id: defaultCountry.id,
  };

  const [formObject, setFormObject] = useState(defaultObject);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  function onChange(value, attribute) {
    setFormObject({
      ...formObject,
      [attribute]: value,
    });
  }

  function onChangeMulti(obj = {}) {
    if (Object.keys(obj).length > 1) {
      setFormObject({
        ...formObject,
        ...obj,
      });
    }
  }

  async function onSave(event) {
    event.preventDefault();
    try {
      setFormErrorMessage(null);

      const responseObject = await requestCreate({
        ...formObject,
        country_id: formObject.country_id || defaultCountry.id,
      });
      setUser(responseObject.data);
      navigate(`/users/${responseObject.data.id}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      setFormErrorMessage(e.response.data.errors[0]);
      window.scrollTo(0, 0);
    }
  }

  return (
    <section className="user-form">
      <NavLink exact to="/" className="d-block mb-4">
        <FontAwesomeIcon icon={faLongArrowLeft} />
        {' '}
        Return to all users
      </NavLink>
      <h1>
        New User
      </h1>
      {formErrorMessage && (
        <Alert color="danger" className="user-form--error">
          {formErrorMessage}
        </Alert>
      )}
      <form onSubmit={onSave}>
        <ContactInformation formObject={formObject} onChange={onChange} />
        <AddressInformation formObject={formObject} onChange={onChange} />
        <SubscriptionManagement
          user={formObject}
          onChange={onChange}
          onChangeMulti={onChangeMulti}
        />
        <div className="actions">
          <Button color="primary" type="submit">
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="mr-2" />
            <b>Send</b>
            {' '}
            invite to software
          </Button>
          <NavLink exact to="/" className="btn btn-secondary ml-3">
            Cancel
          </NavLink>
        </div>
      </form>
    </section>
  );
};

UserEdit.propTypes = {
  setUser: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default UserEdit;
