import {
  REQUEST_LOGIN_AS_USER,
  SET_USER,
  REQUEST_USER_FETCH,
} from '../constants/users';

export function setUser(user) {
  return { type: SET_USER, payload: user };
}

export function loginAsUser(userId) {
  return { type: REQUEST_LOGIN_AS_USER, payload: userId };
}

export function requestSingleUser(userId) {
  return { type: REQUEST_USER_FETCH, payload: userId };
}
