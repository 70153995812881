import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const DealerUserPermission = ({ dealerUserPermission, togglePermission }) => (
  <>
    <tr style={{ borderTop: '1px solid #dee2e6' }}>
      <td>
        <div className="d-flex flex-row align-items-center">
          <div className="user-details">
            <h5>
              {dealerUserPermission.user_name}
            </h5>
          </div>
        </div>
      </td>
      <td style={{ paddingLeft: 0, verticalAlign: 'middle' }}>
        <div className="d-flex flex-row align-items-center">
          <div className="user-details">
            <h5>
              <Badge color={dealerUserPermission.access ? 'success' : 'danger'}>
                {dealerUserPermission.access ? 'HAS ACCESS' : 'NO ACCESS'}
              </Badge>
            </h5>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex flex-row align-items-center">
          <div className="user-details">
            <button type="submit" className="shadow-sm btn btn-secondary" onClick={e => togglePermission(dealerUserPermission.id, e)} style={{ cursor: 'pointer' }}>
              <b>{dealerUserPermission.access ? 'Revoke' : 'Grant'}</b>
              {' '}
              <FontAwesomeIcon
                icon={dealerUserPermission.access ? faBan : faCheckCircle}
              />
            </button>
          </div>
        </div>
      </td>
    </tr>
  </>
);

DealerUserPermission.propTypes = {
  dealerUserPermission: PropTypes.shape({
    id: PropTypes.number.isRequired,
    access: PropTypes.bool.isRequired,
    user_name: PropTypes.string.isRequired,
  }).isRequired,
  togglePermission: PropTypes.func.isRequired,
};

export default DealerUserPermission;
