import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import Api from '../utilities/Api';

import {
  REQUEST_RESET_PASSWORD,
} from '../constants/login';

function* resetPassword(email, url) {
  try {
    yield call(Api.post, '/consultants/forgot_password', { email });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  yield put(push(url));
}

export default function* watchForLogin() {
  while (true) {
    const action = yield take(REQUEST_RESET_PASSWORD);
    yield call(resetPassword, action.email, action.url);
  }
}
