import PropTypes from 'prop-types';
import React from 'react';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DealershipContactInfo = ({ dealership }) => (
  <Card className="shadow user--contact-details mb-4">
    <CardBody>
      <Row>
        <Col className="mr-4">
          <h3>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
            Address
          </h3>
          {(dealership.address || '').length > 0 ? (
            <address>
              {dealership.address}
              <br />
              {dealership.city}
              ,
              {' '}
              {dealership.state}
              {' '}
              {dealership.postal_code}
              {' '}
              <br />
              {dealership.country !== '' && dealership.country }
            </address>
          ) : (<h5>No Address Information Set</h5>)}
        </Col>
        <Col>
          <h3>
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            Contact Information
          </h3>
          <address className="user--contact-info">
            Email:
            {' '}
            <a href={`mailto:${dealership.email}`}>{dealership.email}</a>
            <br />
            {dealership.phone && (
              <>
                Phone:
                {' '}
                <a href={`tel:${dealership.phone}`}>{dealership.phone}</a>
              </>
            )}
          </address>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

DealershipContactInfo.propTypes = {
  dealership: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    address: PropTypes.string,
    postal_code: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default DealershipContactInfo;
