import { connect } from 'react-redux';

import { loginAsUser } from '../../actions/users';
import LoginAsUser from '../../components/User/LoginAsUser';

const mapDispatchToProps = {
  loginAsUser,
};

function mapStateToProps(state) {
  return {
    dealer_user: state.dealerUser.dealer_user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAsUser);
