import _ from 'lodash';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import history from '../../store/history';

import AuthenticatedRoutes from './authenticated';
import Login from '../../containers/Authentication/Login';
import PasswordReset from '../../containers/Authentication/PasswordReset';
import PasswordResetSent from '../../components/Authentication/PasswordResetSent';
import Loading from '../../containers/Loading';

/**
 * Renders all the routes used in the app.
 * Be sure to use functional components and the `render` method if possible, instead of a component
 * https://reacttraining.com/react-router/web/api/Route/component
 */
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.dealer_user)) {
      this.props.fetchDealerUser(this.props.dealer_user.id);
    }
  }

  componentDidUpdate(prevProps) {
    const wasFetching = prevProps.status.initial_fetch;
    const isFetching = this.props.status.initial_fetch;
    if (wasFetching && !isFetching) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1500);
    }
  }

  render() {
    const { dealer_user: dealerUser } = this.props;
    const { loading } = this.state;
    const authenticated = !_.isEmpty(dealerUser);

    return (
      <ConnectedRouter history={history}>
        {loading && (<Loading />)}
        {authenticated ? (
          <AuthenticatedRoutes />
        ) : (
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/password-reset/sent" component={PasswordResetSent} />
            <Route exact path="/password-reset" component={PasswordReset} />
          </Switch>
        )}
      </ConnectedRouter>
    );
  }
}

Routes.propTypes = {
  dealer_user: PropTypes.shape({ id: PropTypes.number }).isRequired,
  fetchDealerUser: PropTypes.func.isRequired,
  status: PropTypes.shape({
    initial_fetch: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Routes;
