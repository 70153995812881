import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from './shared/CustomIcons';
import ContactInformation from './UserForm/ContactInformation';
import AddressInformation from './UserForm/AddressInformation';
import SubscriptionManagement from '../containers/UserSubscriptionManagement';
import { requestUpdate } from './UserForm/FormApiRequests';
import UserNotFound from './UserNotFound';

const UserEdit = ({ user, setUser, navigate }) => {
  const [formObject, setFormObject] = useState({});

  useEffect(() => {
    if (user && user.dealer_demonstration === true) {
      navigate(`/demonstrations/${user.id}/edit`);
    }

    setFormObject(user);
  }, user.id);

  function onChange(value, attribute) {
    setFormObject({
      ...formObject,
      [attribute]: value,
    });
  }

  async function onSave(event) {
    event.preventDefault();
    try {
      const responseObject = await requestUpdate(formObject);
      setUser(responseObject.data);
      navigate(`/users/${responseObject.data.id}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }

  if (isEmpty(user)) return <UserNotFound />;

  return (
    <section className="user-form">
      <NavLink exact to="/" className="d-block mb-4">
        <FontAwesomeIcon icon={faLongArrowLeft} />
        {' '}
        Return to all users
      </NavLink>
      <h1>
        {'Editing '}
        {(user.name || 'User').split(' ')[0]}
      </h1>
      <form onSubmit={onSave}>
        <ContactInformation formObject={formObject} onChange={onChange} />
        <AddressInformation formObject={formObject} onChange={onChange} />
        <div className="actions">
          <Button color="primary" type="submit">
            <FontAwesomeIcon icon={faCloud} className="mr-2" />
            <b>Save</b>
            {' '}
            changes
          </Button>
          <NavLink exact to={`/users/${user.id}`} className="btn btn-secondary ml-3">
            Cancel
          </NavLink>
        </div>
        <hr />
        <SubscriptionManagement user={formObject} onChange={onChange} />
      </form>
    </section>
  );
};

UserEdit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    demo_account: PropTypes.bool,
    dealer_demonstration: PropTypes.bool,
  }),
  setUser: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

UserEdit.defaultProps = {
  user: {},
};

export default UserEdit;
