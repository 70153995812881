import { all, fork } from 'redux-saga/effects';

import watchForFetchRequestDealershipDealerUsers from './dealership_dealer_users/fetch';
import watchForCreateDealershipDealerUser from './dealership_dealer_users/create';
import watchForUpdateDealershipDealerUser from './dealership_dealer_users/update';
import watchForDeleteDealershipDealerUser from './dealership_dealer_users/delete';
import watchForUpdateDealershipDealerUserPermisisons from './dealership_dealer_users/toggle_permission';
import watchForFetchRequestSingleDealershipDealerUsers from './dealership_dealer_users/fetchSingleDealerUser';

export default function* dealership() {
  yield all([
    fork(watchForFetchRequestDealershipDealerUsers),
    fork(watchForCreateDealershipDealerUser),
    fork(watchForUpdateDealershipDealerUser),
    fork(watchForDeleteDealershipDealerUser),
    fork(watchForUpdateDealershipDealerUserPermisisons),
    fork(watchForFetchRequestSingleDealershipDealerUsers),
  ]);
}
