import { REQUEST_LOGIN, REQUEST_RESET_PASSWORD } from '../constants/login';

/* eslint-disable import/prefer-default-export */

export function login(email, password) {
  return {
    type: REQUEST_LOGIN,
    email,
    password,
    url: '/',
  };
}


export function resetPassword(email) {
  return {
    type: REQUEST_RESET_PASSWORD,
    email,
    url: '/password-reset/sent',
  };
}
