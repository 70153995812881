import PropTypes from 'prop-types';
import React from 'react';
import SparkMD5 from 'spark-md5';

const UserListItemImage = ({ user, size }) => {
  const emailMd5 = SparkMD5.hash(user.email.trim().toLowerCase());
  const gravatarImgUrl = `https://www.gravatar.com/avatar/${emailMd5}.jpg?s=50&d=mp`;
  return (
    <img
      alt={user.email}
      className="rounded-circle"
      src={gravatarImgUrl}
      style={{ height: size, width: size }}
      title={`${user.name}'s Gravatar`}
    />
  );
};

UserListItemImage.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  size: PropTypes.string,
};

UserListItemImage.defaultProps = {
  size: '40px',
};

export default UserListItemImage;
