import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

const SubscriptionManagementNewUser = ({ user, onChangeMulti }) => {
  function changeAccountType(type) {
    if (type === 'demo') {
      onChangeMulti({ demo_account: true, dealer_demonstration: false });
    } else if (type === 'paid') {
      onChangeMulti({ demo_account: false, dealer_demonstration: false });
    } else if (type === 'dealer_demo') {
      onChangeMulti({ demo_account: false, dealer_demonstration: true });
    }
  }

  console.log(user);

  return (
    <div className="d-flex flex-row new-user">
      <Button color={user.demo_account ? 'primary' : 'secondary'} onClick={() => changeAccountType('demo')}>
        <div className="button-line-1">14 Day</div>
        <div className="button-line-2">Free Trial</div>
        <div className="button-line-3">
          Allow the user to try out the software free of charge.
        </div>
      </Button>
      <Button color={!user.demo_account && !user.dealer_demonstration ? 'primary' : 'secondary'} onClick={() => changeAccountType('paid')}>
        <div className="button-line-1">Paid</div>
        <div className="button-line-2">Account</div>
        <div className="button-line-3">
          You will be billed for this user’s subscription.
        </div>
      </Button>
      <Button color={user.dealer_demonstration ? 'primary' : 'secondary'} onClick={() => changeAccountType('dealer_demo')}>
        <div className="button-line-1">Demonstration</div>
        <div className="button-line-2">Account</div>
        <div className="button-line-3">
          You will not be billed for this user.
        </div>
      </Button>
    </div>
  );
};

SubscriptionManagementNewUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    demo_account: PropTypes.bool,
    dealer_demonstration: PropTypes.bool,
  }).isRequired,
  onChangeMulti: PropTypes.func.isRequired,
};

export default SubscriptionManagementNewUser;
