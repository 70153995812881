import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setUser } from '../../actions/users';
import { getUserByID } from '../../reducers/users';

import DemonstrationEdit from '../../components/Demonstrations/DemonstrationEdit';

const mapDispatchToProps = {
  setDemonstration: setUser,
  navigate: push,
};

function mapStateToProps(state, props) {
  const demonstrationId = parseInt(props.match.params.id, 10);
  return {
    demonstration: getUserByID(state, demonstrationId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DemonstrationEdit);
