import { call, put, take } from 'redux-saga/effects';
import Api from '../../utilities/Api';

import {
  DEALERSHIP_DEALER_USER_REQUEST_DELETE,
  DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
  DEALERSHIP_DEALER_USER_REQUEST_DELETE_SUCCESS,
} from '../../constants/dealership_dealer_users';

export function* deleteDealerUser(id) {
  yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS });
  try {
    yield call(
      Api.deleteAuthenticated,
      `/consultants/${id}`,
    );
    yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_DELETE_SUCCESS, id });
  } catch (error) {
    if (error.response && error.response.data) {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: error.response.data.error });
    } else {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: 'Something went wrong. Please try again' });
    }
  }
}

export default function* watchForDeleteDealershipDealerUser() {
  while (true) {
    const action = yield take(DEALERSHIP_DEALER_USER_REQUEST_DELETE);
    yield call(deleteDealerUser, action.id);
  }
}
