import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import {
  REQUEST_DEALER_USER_RESET,
} from '../constants/dealer_user';
import { REQUEST_LOGOUT } from '../constants/logout';
import Api from '../utilities/Api';

function* logout() {
  yield call(Api.setAuthCookie, null);
  yield put({ type: REQUEST_DEALER_USER_RESET });
  yield put(push('/login'));
}

export default function* watchForLogout() {
  while (true) {
    const logoutAction = yield take(REQUEST_LOGOUT);
    yield call(logout, logoutAction.url);
  }
}
