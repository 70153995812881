import { connect } from 'react-redux';
import { login } from '../../actions/login';
import Login from '../../components/Authentication/Login';

const mapDispatchToProps = {
  login,
};

function mapStateToProps(state) {
  return {
    count: state.login.count,
    message: state.login.message,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
