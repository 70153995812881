import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

const ActivityCheckbox = ({ boolean }) => {
  if (boolean) {
    return (
      <FontAwesomeIcon icon={faCheck} />
    );
  }

  return (
    <FontAwesomeIcon icon={faMinus} style={{ opacity: 0.4 }} />
  );
};

ActivityCheckbox.propTypes = {
  boolean: PropTypes.bool.isRequired,
};

export default ActivityCheckbox;
