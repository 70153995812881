import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

import DemonstrationList from './Demonstrations/DemonstrationList';

class Demonstrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };

    this.increasePage = this.increasePage.bind(this);
    this.decreasePage = this.decreasePage.bind(this);

    this.setPageSize = 8;
  }

  increasePage() {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
  }

  decreasePage() {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1 });
  }

  currentPageDemonstrations(demonstrations) {
    const { currentPage } = this.state;
    // eslint-disable-next-line max-len
    return demonstrations.slice((currentPage - 1) * this.setPageSize, currentPage * this.setPageSize);
  }

  isGoBackDisabled() {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      return false;
    }
    return true;
  }

  isNextPageDisabled(maxPage) {
    const { currentPage } = this.state;
    if (currentPage < maxPage) {
      return false;
    }
    return true;
  }

  render() {
    const { navigate } = this.props;


    const maxPage = Math.ceil(this.props.demonstrations.length / this.setPageSize);
    const demonstrations = this.currentPageDemonstrations(this.props.demonstrations);

    return (
      <section className="users">
        <div>
          <h1>Demonstrations</h1>
        </div>
        <div>
          <nav className="">
            <NavLink className="btn btn-primary" to="/demonstrations/new">
              <FontAwesomeIcon icon={faPlus} />
              {' '}
              Add Demonstration
            </NavLink>
          </nav>
        </div>
        <div>
          <Card className="mt-3 mb-3 shadow" style={{ minHeight: '70vh' }}>
            {this.props.fetching ? (
              <div style={{ minHeight: '60vh', opacity: 0.6 }} className="d-flex flex-column align-items-center justify-content-center">
                <div style={{ fontSize: 50 }}>
                  <FontAwesomeIcon pulse icon={faSpinner} />
                </div>
                Fetching Demonstrations...
              </div>
            ) : <DemonstrationList demonstrations={demonstrations} navigate={navigate} />}
          </Card>
        </div>
        <div>
          <button type="submit" onClick={this.decreasePage} className="btn btn-secondary" disabled={this.isGoBackDisabled()}>
            {' '}
            Go Back
          </button>
          <button type="submit" onClick={this.increasePage} className="btn btn-primary float-right" disabled={this.isNextPageDisabled(maxPage)}>
            {' '}
            Next Page
          </button>
        </div>
      </section>
    );
  }
}

Demonstrations.propTypes = {
  navigate: PropTypes.func.isRequired,
  demonstrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetching: PropTypes.bool,
};

Demonstrations.defaultProps = {
  fetching: false,
};

export default Demonstrations;
