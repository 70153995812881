import PropTypes from 'prop-types';
import React from 'react';
import YearlyMetric from './YearlyMetric';

const YearlyMetrics = ({ accountMetrics }) => {
  if (accountMetrics.yearly_metrics.length < 1) return null;

  return (
    <>
      <hr className="mt-4" />
      {accountMetrics.yearly_metrics.map(yearlyMetric => <YearlyMetric metrics={yearlyMetric} />)}
    </>
  );
};

YearlyMetrics.propTypes = {
  accountMetrics: PropTypes.shape({
    yearly_metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default YearlyMetrics;
