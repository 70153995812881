import {
  REQUEST_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS,
  REQUEST_DEALERSHIP_DEALER_USER_UPDATE_SUCCESS,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
  DEALERSHIP_DEALER_USER_CREATED,
  DEALERSHIP_DEALER_USER_UPDATING,
  DEALERSHIP_DEALER_USER_REQUEST_DELETE_SUCCESS,
  DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS,
  REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS,
} from '../constants/dealership_dealer_users';

import { removeSingleItemById, updateSingleItemById } from './helper/index';

export const dealershipDealerUsersInitialState = { error: '', dealer_users: [], updating: false };

export function getDealershipDealerUsers(state) {
  return state.dealershipDealerUsers.dealer_users;
}

export default function dealershipReducer(state = dealershipDealerUsersInitialState, action) {
  switch (action.type) {
    case REQUEST_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS:
      return {
        ...state,
        dealer_users: action.payload,
      };
    case REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS:
      return {
        ...state,
        dealer_users: updateSingleItemById(state.dealer_users, action.payload),
      };
    case DEALERSHIP_DEALER_USER_UPDATING:
      return { ...state, updating: true };
    case REQUEST_DEALERSHIP_DEALER_USER_UPDATE_SUCCESS:
      return {
        ...state,
        dealer_users: updateSingleItemById(state.dealer_users, action.payload),
        updating: false,
      };
    case DEALERSHIP_DEALER_USER_CREATED:
      return {
        ...state,
        dealer_users: [...state.dealer_users, action.payload],
      };
    case DEALERSHIP_DEALER_USER_REQUEST_DELETE_SUCCESS:
      return {
        ...state,
        dealer_users: removeSingleItemById(state.dealer_users, action.id),
      };
    case DEALERSHIP_DEALER_USER_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
}
