/* eslint-disable jsx-a11y/no-autofocus */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import Layout from './Layout';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
    this.updateEmail = this.updateEmail.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateEmail(event) {
    const email = event.target.value;
    this.setState({ email });
  }

  submit(event) {
    event.preventDefault();
    this.props.resetPassword(
      this.state.email,
    );
  }

  render() {
    return (
      <Layout title="Reset Password">
        <Form onSubmit={this.submit}>
          <InputGroup className="inline-group-text">
            <input
              type="email"
              name="email"
              id="email"
              autoFocus
              className="form-control"
              onChange={this.updateEmail}
              placeholder="Email"
              value={this.state.email}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <Button block color="primary" type="submit">Send Reset Link</Button>
          <NavLink exact to="/login" className="d-block text-center">Sign In</NavLink>
        </Form>
      </Layout>
    );
  }
}

PasswordReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

export default PasswordReset;
