import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/browser';
import Api from '../../utilities/Api';


const BillingPortalButton = ({ user }) => {
  if (!user.stripe_customer_id) return null;

  const onBillingPortalClick = async () => {
    try {
      const stripeBillingPortalUrlResponse = await Api.getAuthenticated(`/users/${user.id}/stripe_billing_portal`);
      window.open(stripeBillingPortalUrlResponse.data.data, '_blank');
    } catch (error) {
      captureException(error);
      window.alert('Something went wrong.');
    }
  };

  return (
    <Button className="mr-3" color="secondary" onClick={onBillingPortalClick}>
      <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
      Billing Portal
    </Button>
  );
};

BillingPortalButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    stripe_customer_id: PropTypes.string,
  }).isRequired,
};

export default BillingPortalButton;
