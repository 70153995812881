import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import Api from '../../utilities/Api';

import {
  REQUEST_DEALER_USER_FETCH,
  REQUEST_DEALER_USER_FETCH_SUCCESS,
} from '../../constants/dealer_user';
import { END_INITIAL_LOAD } from '../../constants/status';

import { REQUEST_USERS_FETCH } from '../../constants/users';

import { REQUEST_DEALERSHIP_FETCH } from '../../constants/dealership';
import { REQUEST_DEALERSHIP_DEALER_USERS_FETCH } from '../../constants/dealership_dealer_users';

function* fetchDealerUser() {
  try {
    const response = yield call(Api.getAuthenticated, '/current_dealer_user');
    const adminDealerUser = response.data.admin_access;

    if (adminDealerUser) {
      yield put({ type: REQUEST_DEALERSHIP_FETCH });
      yield put({ type: REQUEST_DEALERSHIP_DEALER_USERS_FETCH });
    }
    yield put({ type: REQUEST_USERS_FETCH });
    yield put({ type: REQUEST_DEALER_USER_FETCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: END_INITIAL_LOAD });
    yield put(push('/login'));
  }
}

export default function* watchForFetchDealerUser() {
  while (true) {
    const action = yield take(REQUEST_DEALER_USER_FETCH);
    yield call(fetchDealerUser, action.id);
  }
}
