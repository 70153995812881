import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootSaga from '../sagas';
import history from './history';
import middleware, { sagaMiddleware } from './middleware';
import createRootReducer from '../reducers/index';

// create the initial state
const initialState = {};

// mount it on the Store
const store = createStore(
  createRootReducer(history),
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware),
  ),
);

// then run the saga
sagaMiddleware.run(rootSaga);

export default store;
