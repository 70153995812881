import PropTypes from 'prop-types';
import React from 'react';

import logo from '../../assets/white-management-logo.png';
import icon from '../../assets/logo-icon.png';

const AuthenticationLayout = ({ children, title }) => (
  <div className="authentication">
    <aside>
      <img
        className="mb-2"
        alt="Harvest Profit Management Portal"
        src={logo}
        style={{ width: 250 }}
      />
      <div className="pb-5">
        <h2>Welcome to the Harvest Profit Management Portal</h2>
        <p>Manage your subscriptions and help keep your customers engaged</p>
      </div>
      <footer>
        <a href="https://www.harvestprofit.com">harvestprofit.com</a>
        <a href="mailto:dealers@harvestprofit.com">Contact Support</a>
      </footer>
    </aside>
    <main>
      <div className="p-2 w-100" style={{ maxWidth: 350 }}>
        <div className="form-icon">
          <img
            alt="Harvest Profit Management Portal Icon"
            src={icon}
          />
        </div>
        <h1>{title}</h1>
        {children}
      </div>
    </main>
  </div>
);

AuthenticationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default AuthenticationLayout;
