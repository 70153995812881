import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import Dealership from '../components/Dealership';

import { fetchDealership } from '../actions/dealership';

import { fetchDealershipDealerUsers, requestDealershipDeleteDealerUser, fetchSingleDealershipDealerUser } from '../actions/dealership_dealer_users';

const mapDispatchToProps = {
  navigate: push,
  fetchDealership,
  fetchDealershipDealerUsers,
  fetchSingleDealershipDealerUser,
  requestDealershipDeleteDealerUser,
};

function mapStateToProps(state) {
  return {
    dealership: state.dealership.dealership,
    dealershipDealerUsers: state.dealershipDealerUsers.dealer_users,
    fetching: state.status.fetching > 0,
    dealer_user: state.dealerUser.dealer_user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dealership);
