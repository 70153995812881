import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import UserFilters from './Users/UserFilters';
import UserList from './Users/UserList';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        inactive: false,
        monthly_active_user: false,
        weekly_active_user: false,
        demo_account: false,
        paid_account: false,
      },
      search: '',
      currentPage: 1,
    };
    this.setFilters = this.setFilters.bind(this);
    this.setSearch = this.setSearch.bind(this);

    this.increasePage = this.increasePage.bind(this);
    this.decreasePage = this.decreasePage.bind(this);

    this.setPageSize = 8;
  }

  setFilters(filters) {
    this.setState({ filters, currentPage: 1 });
  }

  setSearch(search) {
    this.setState({ search, currentPage: 1 });
  }

  increasePage() {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
  }

  decreasePage() {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1 });
  }

  currentPageUsers(users) {
    const { currentPage } = this.state;
    return users.slice((currentPage - 1) * this.setPageSize, currentPage * this.setPageSize);
  }

  isGoBackDisabled() {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      return false;
    }
    return true;
  }

  isNextPageDisabled(maxPage) {
    const { currentPage } = this.state;
    if (currentPage < maxPage) {
      return false;
    }
    return true;
  }

  render() {
    const { navigate } = this.props;

    let { users } = this.props;

    const { filters, search } = this.state;
    if (filters.inactive) {
      users = users.filter(u => u.inactive_user === true);
    }
    if (filters.monthly_active_user) {
      users = users.filter(u => u.monthly_active_user === true);
    }
    if (filters.weekly_active_user) {
      users = users.filter(u => u.weekly_active_user === true);
    }

    if (filters.demo_account) {
      users = users.filter(u => u.demo_account === true);
    }

    if (filters.paid_account) {
      users = users.filter(u => u.demo_account === false);
    }

    if (search !== '') {
      users = users.filter(
        u => u.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
          || (u.name && u.name.toLowerCase().indexOf(search.toLowerCase()) !== -1),
      );
    }

    const maxPage = Math.ceil(users.length / this.setPageSize);
    users = this.currentPageUsers(users);


    return (
      <section className="users">
        <h1>Users</h1>
        <div>
          <UserFilters
            filters={filters}
            search={search}
            setFilters={this.setFilters}
            setSearch={this.setSearch}
          />
          <Card className="mb-3 shadow" style={{ minHeight: '70vh' }}>
            {this.props.fetching ? (
              <div style={{ minHeight: '60vh', opacity: 0.6 }} className="d-flex flex-column align-items-center justify-content-center">
                <div style={{ fontSize: 50 }}>
                  <FontAwesomeIcon pulse icon={faSpinner} />
                </div>
                Fetching Users...
              </div>
            ) : <UserList users={users} navigate={navigate} />}
          </Card>
        </div>
        <div>
          <button type="submit" onClick={this.decreasePage} className="btn btn-secondary" disabled={this.isGoBackDisabled()}>
            {' '}
            Go Back
          </button>
          <button type="submit" onClick={this.increasePage} className="btn btn-primary float-right" disabled={this.isNextPageDisabled(maxPage)}>
            {' '}
            Next Page
          </button>
        </div>
      </section>
    );
  }
}

Users.propTypes = {
  navigate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetching: PropTypes.bool,
};

Users.defaultProps = {
  fetching: false,
};

export default Users;
