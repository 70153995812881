import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import Api from '../utilities/Api';

import {
  REQUEST_DEALER_USER_FETCH,
} from '../constants/dealer_user';
import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN_FAILURE,
} from '../constants/login';

function* login(email, password, url) {
  try {
    const response = yield call(Api.post, '/login', { email, password });
    yield call(Api.setAuthCookie, response.data.auth_token);
    yield put({ type: REQUEST_LOGIN_SUCCESS });
    yield put({ type: REQUEST_DEALER_USER_FETCH });
    yield put(push(url));
  } catch (error) {
    if (error.response && error.response.data) {
      const { response } = error;
      yield put({ type: REQUEST_LOGIN_FAILURE, payload: response.data.error });
    } else {
      yield put({ type: REQUEST_LOGIN_FAILURE, payload: 'Something went wrong, please try again.' });
    }
    yield put(push('/login'));
  }
}

export default function* watchForLogin() {
  while (true) {
    const action = yield take(REQUEST_LOGIN);
    yield call(login, action.email, action.password, action.url);
  }
}
