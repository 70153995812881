import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaUp,
  faSortAlphaDown,
  faSortNumericUp,
  faSortNumericDown,
  faSortAmountUp,
  faSortAmountDown,
  faSort,
} from '@fortawesome/free-solid-svg-icons';


const SortableTableHeaderItem = (props) => {
  let sortUp = faSortAmountUp;
  let sortDown = faSortAmountDown;
  switch (props.type) {
    case 'number':
      sortUp = faSortNumericUp;
      sortDown = faSortNumericDown;
      break;
    case 'string':
      sortUp = faSortAlphaUp;
      sortDown = faSortAlphaDown;
      break;
    default:
      sortUp = faSortAmountUp;
      sortDown = faSortAmountDown;
  }

  const sortRule = props.sortRules[props.sortBy];
  let filterIcon = faSort;
  let styles = { opacity: 0.3, width: '20px' };
  if (sortRule) {
    if (sortRule === 'desc') {
      filterIcon = sortUp;
    } else {
      filterIcon = sortDown;
    }
    styles = { width: '20px' };
  }

  const Tag = props.tag;

  let rightIcon = null;
  let leftIcon = null;

  if (props.right && !props.left) {
    rightIcon = (<FontAwesomeIcon icon={filterIcon} className="ml-1 color-dark" style={styles} />);
  } else {
    leftIcon = (<FontAwesomeIcon icon={filterIcon} className="mr-1 color-dark" style={styles} />);
  }

  return (
    <Tag className="text-noselect text-nowrap text-truncate" style={{ cursor: 'pointer' }} onClick={() => props.onClick(props.sortBy)}>
      {leftIcon}
      {props.children}
      {rightIcon}
    </Tag>
  );
};

SortableTableHeaderItem.propTypes = {
  /** An object where the keys are the attributes and the values are either 'asc' or 'desc' */
  sortRules: PropTypes.shape({}).isRequired,
  /** The attribute that will be sorted with */
  sortBy: PropTypes.string.isRequired,
  /** Table header contents */
  children: PropTypes.node,
  /** Change the sort */
  onClick: PropTypes.func.isRequired,
  tag: PropTypes.string,
  type: PropTypes.oneOf(['number', 'string', 'generic']),
  right: PropTypes.bool,
  left: PropTypes.bool,
};

SortableTableHeaderItem.defaultProps = {
  children: null,
  tag: 'div',
  type: 'generic',
  right: true,
  left: false,
};

export default SortableTableHeaderItem;
