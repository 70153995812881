import PropTypes from 'prop-types';
import React from 'react';
// import Progress from './UserListItem/UserListItemProgress';

const DemonstrationListItem = (props) => {
  const { demonstration } = props;

  return (
    <tr onClick={() => props.navigate(`/demonstrations/${demonstration.id}`)} style={{ cursor: 'pointer' }}>
      <td>
        <div className="d-flex flex-row align-items-center">
          {' '}
          <div className="ml-2 user-details">
            <h5>
              {demonstration.name}
            </h5>
          </div>
        </div>
      </td>
      <td className="user-activity">
        <div>
          Total Visits:
          {' '}
          {demonstration.sign_in_count}
        </div>
      </td>
    </tr>
  );
};

DemonstrationListItem.propTypes = {
  navigate: PropTypes.func.isRequired,
  demonstration: PropTypes.shape({
    id: PropTypes.number,
    demo_expires_at: PropTypes.string,
    demo_account: PropTypes.bool,
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    cell_phone: PropTypes.string,
    current_sign_in_at: PropTypes.string,
    weekly_active_user: PropTypes.bool,
    monthly_active_user: PropTypes.bool,
    inactive_user: PropTypes.bool,
    sign_in_count: PropTypes.number,
    // account_metrics: PropTypes.shape({
    //   progress: PropTypes.number,
    // }).isRequired,
  }).isRequired,
};

export default DemonstrationListItem;
