import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleInactive = this.toggleInactive.bind(this);
    this.toggleMonthlyUsers = this.toggleMonthlyUsers.bind(this);
    this.toggleWeeklyUsers = this.toggleWeeklyUsers.bind(this);
    this.toggleDemoUsers = this.toggleDemoUsers.bind(this);
    this.togglePaidUsers = this.togglePaidUsers.bind(this);
  }

  toggle() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  toggleInactive() {
    this.props.setFilters({
      inactive: !this.props.filters.inactive,
    });
  }

  toggleMonthlyUsers() {
    this.props.setFilters({
      monthly_active_user: !this.props.filters.monthly_active_user,
    });
  }

  toggleWeeklyUsers() {
    this.props.setFilters({
      weekly_active_user: !this.props.filters.weekly_active_user,
    });
  }

  toggleDemoUsers() {
    this.props.setFilters({
      demo_account: !this.props.filters.demo_account,
    });
  }

  togglePaidUsers() {
    this.props.setFilters({
      paid_account: !this.props.filters.paid_account,
    });
  }

  render() {
    const { filters } = this.props;
    const { open } = this.state;
    return (
      <Dropdown isOpen={open} toggle={this.toggle}>
        <DropdownToggle>
          <FontAwesomeIcon icon={faFilter} />
          {' '}
          Filters
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.toggleDemoUsers} toggle={false}>
            {filters.demo_account ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
            {' '}
            Trial Users
          </DropdownItem>
          <DropdownItem onClick={this.togglePaidUsers} toggle={false}>
            {filters.paid_account ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
            {' '}
            Paid Users
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.toggleInactive} toggle={false}>
            {filters.inactive ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
            {' '}
            Inactive
          </DropdownItem>
          <DropdownItem onClick={this.toggleMonthlyUsers} toggle={false}>
            {filters.monthly_active_user ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
            {' '}
            Monthly Active Users
          </DropdownItem>
          <DropdownItem onClick={this.toggleWeeklyUsers} toggle={false}>
            {filters.weekly_active_user ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
            {' '}
            Weekly Active Users
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

Filters.propTypes = {
  filters: PropTypes.shape({
    inactive: PropTypes.bool,
    monthly_active_user: PropTypes.bool,
    weekly_active_user: PropTypes.bool,
    demo_account: PropTypes.bool,
    paid_account: PropTypes.bool,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
