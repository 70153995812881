import PropTypes from 'prop-types';
import React from 'react';
import styles from './Loading.module.scss';
import image from '../assets/white-management-logo.png';

const Loading = ({ status }) => {
  const initialFetch = status.initial_fetch;
  return (
    <div className={initialFetch ? styles.loading : [styles.loading, styles.loadingAnimation].join(' ')}>
      <div className={styles.container}>
        {!initialFetch && (<div className={styles.circle} />)}
        <img className={styles.image} src={image} alt="Harvest Profit" />
      </div>
    </div>
  );
};

Loading.propTypes = {
  status: PropTypes.shape({
    initial_fetch: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Loading;
