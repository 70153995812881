import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Input, Label } from 'reactstrap';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from './shared/CustomIcons';
import Api from '../utilities/Api';

const PasswordManagement = ({ navigate }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  async function onSave(event) {
    event.preventDefault();
    try {
      setErrorMessage(null);
      await Api.postAuthenticated('/consultants/change_password', {
        old_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmNewPassword,
      });
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data) {
        const { response } = error;
        setErrorMessage(response.data.error);
      } else {
        setErrorMessage('Something went wrong, please try again');
      }
    }
  }

  return (
    <section className="user-form">
      <NavLink exact to="/" className="d-block mb-4">
        <FontAwesomeIcon icon={faLongArrowLeft} />
        {' '}
        Return to all users
      </NavLink>
      <h1>
        Change Password
      </h1>
      <form onSubmit={onSave}>
        {errorMessage && (
          <p className="my-2 text-danger">{errorMessage}</p>
        )}
        <Card className="shadow user-form--contact-information mb-4">
          <CardBody>
            <div>
              <div className="mb-2">
                <Label for="current-password">Current Password</Label>
                <Input id="current-password" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
              </div>
              <hr />
              <div className="mb-2">
                <Label for="new-password">New Password</Label>
                <Input id="new-password" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
              </div>
              <div className="mb-2">
                <Label for="confirm-new-password">Confirm New Password</Label>
                <Input id="confirm-new-password" type="password" value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="actions">
          <Button color="primary" type="submit">
            <FontAwesomeIcon icon={faKey} className="mr-2" />
            <b>Change</b>
            {' '}
            password
          </Button>
          <NavLink exact to="/" className="btn btn-secondary ml-3">
            Cancel
          </NavLink>
        </div>
      </form>
    </section>
  );
};

PasswordManagement.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default PasswordManagement;
