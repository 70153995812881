import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input } from 'reactstrap';

import Filters from './UserFilters/Filters';

const UserFilters = (props) => {
  const { filters, search } = props;
  const onSearch = event => props.setSearch(event.target.value);
  return (
    <nav className="my-3 d-flex flex-row justify-content-between">
      <div className="d-flex flex-row align-items-center">
        <Filters filters={filters} setFilters={props.setFilters} />
        <Form inline className="pl-3 d-none d-xl-block">
          <Input onChange={onSearch} value={search} placeholder="Search" />
        </Form>
      </div>
      <NavLink className="btn btn-primary" to="/users/new">
        <FontAwesomeIcon icon={faPlus} />
        {' '}
        Add User
      </NavLink>
    </nav>
  );
};

UserFilters.propTypes = {
  filters: PropTypes.shape({
    inactive: PropTypes.bool,
    monthly_active_user: PropTypes.bool,
    weekly_active_user: PropTypes.bool,
  }).isRequired,
  search: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default UserFilters;
