import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const beaconID = '9fcdd45e-35c4-4612-84b5-e429246c3456';
const beaconConfig = {
  display: { style: 'manual', zIndex: 2080 },
  messaging: {
    showPrefilledCustomFields: true,
  },
  contactForm: {
    showPrefilledCustomFields: true,
    showName: true,
  },
};

const Support = (props) => {
  const [initialized, setInitialized] = useState(false);

  function initializeSupport() {
    if (!props.dealerUserID) return;
    if (initialized) return;

    const { Beacon } = window;
    if (Beacon) {
      Beacon('init', beaconID);
      Beacon('config', beaconConfig);
      Beacon('identify', {
        name: props.dealerUserName,
        email: props.dealerUserEmail,
      });
    }
    setInitialized(true);
  }

  useEffect(() => {
    initializeSupport();
  });

  function clickSupport() {
    const { Beacon } = window;
    if (Beacon && document.getElementById('beacon-container')) {
      Beacon('prefill', {
        name: props.dealerUserName,
        email: props.dealerUserEmail,
      });
      Beacon('toggle');
    } else {
      // eslint-disable-next-line no-alert
      alert('Uh Oh, looks like our support client isn\'t working.  You can send a support ticket directly to dealers@harvestprofit.com.  We apologize for the inconvenience.');
      window.location.href = 'mailto:dealers@harvestprofit.com';
    }
  }

  return (
    // eslint-disable-next-line
    <a href="#" onClick={clickSupport} className="text-dark">
      <FontAwesomeIcon className="mr-1" icon={faLifeRing} />
      {' '}
      Help
    </a>
  );
};

Support.propTypes = {
  dealerUserName: PropTypes.string,
  dealerUserEmail: PropTypes.string,
  dealerUserID: PropTypes.number,
};

Support.defaultProps = {
  dealerUserName: null,
  dealerUserEmail: null,
  dealerUserID: null,
};

export default Support;
