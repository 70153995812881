import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPassword } from '../../actions/login';
import PasswordReset from '../../components/Authentication/PasswordReset';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    resetPassword,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(PasswordReset);
