import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Table } from 'reactstrap';

import DemonstrationListHeader from './DemonstrationList/DemonstrationListHeader';
import DemonstrationListItem from './DemonstrationList/DemonstrationListItem';
import { orderBy } from '../../utilities/application_helper';

const DemonstrationList = (props) => {
  const { navigate, demonstrations } = props;
  const [sortBy, setSortBy] = useState({ activity: 'desc' });

  function changeSortBy(sortKey) {
    if (!sortBy[sortKey]) {
      setSortBy({ [sortKey]: 'asc' });
    } else if (sortBy[sortKey] === 'asc') {
      setSortBy({ [sortKey]: 'desc' });
    } else {
      setSortBy({ [sortKey]: 'asc' });
    }
  }

  const sortableDemonstrations = demonstrations.map(demonstration => ({
    ...demonstration,
    activity: new Date(demonstration.current_sign_in_at || '1990-01-01'),
  }));

  // eslint-disable-next-line max-len
  const orderedDemonstrations = orderBy(sortableDemonstrations, Object.keys(sortBy)[0], Object.values(sortBy)[0]);

  return (
    <Table hover responsive>
      <thead className="card-header">
        <DemonstrationListHeader onChangeSortBy={changeSortBy} sortBy={sortBy} />
      </thead>
      <tbody className="card-body">
        {orderedDemonstrations.map(
          d => <DemonstrationListItem key={d.id} demonstration={d} navigate={navigate} />,
        )}
      </tbody>
    </Table>
  );
};

DemonstrationList.propTypes = {
  navigate: PropTypes.func.isRequired,
  demonstrations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DemonstrationList;
