import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressCheckbox from './ProgressCheckbox';

const YearlyMetric = ({ metrics }) => {
  let lastUpdate = 'Never';
  if (metrics.last_updated) {
    lastUpdate = moment(metrics.last_updated).calendar(null, { sameElse: 'MMMM D, YYYY' });
  }

  return (
    <div className="user--account-yearly-metrics my-4">
      <h3>
        {metrics.year}
        {' '}
        <small>
          {'Updated: '}
          <time>{lastUpdate}</time>
        </small>
      </h3>
      <Card className="shadow">
        <CardBody className="d-flex flex-row align-items-center">
          <div style={{ width: 80, height: 80 }} className="mr-5">
            <CircularProgressbar value={metrics.progress * 100} text={`${(metrics.progress * 100).toFixed(0)}%`} strokeWidth={10} />
          </div>
          <div>
            <div>
              <ProgressCheckbox boolean={metrics.added_land_costs} />
              {' '}
              Land Costs Added
            </div>
            <div>
              <ProgressCheckbox boolean={metrics.added_overhead_inputs} />
              {' '}
              Overhead Inputs Added
            </div>
            <div>
              <ProgressCheckbox boolean={metrics.added_crop_plan} />
              {' '}
              Crop Plan Added
            </div>
            <div>
              <ProgressCheckbox boolean={metrics.added_applications} />
              {' '}
              Applications Added
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

YearlyMetric.propTypes = {
  metrics: PropTypes.shape({
    progress: PropTypes.number,
    added_land_costs: PropTypes.bool,
    added_overhead_inputs: PropTypes.bool,
    added_crop_plan: PropTypes.bool,
    added_applications: PropTypes.bool,
    last_updated: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
};

export default YearlyMetric;
