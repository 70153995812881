import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { UncontrolledTooltip, Badge } from 'reactstrap';
import Image from './UserListItem/UserListItemImage';
import UserDate from './UserListItem/UserListItemDate';
// import Progress from './UserListItem/UserListItemProgress';

const UserListItem = (props) => {
  const { user } = props;
  const onClick = () => props.navigate(`/users/${user.id}`);
  let status = 'Inactive';
  if (user.monthly_active_user) status = 'MAU';
  if (user.weekly_active_user) status = 'WAU';

  let demo = null;
  if (user.demo_account) {
    const demoExpiresAt = moment(user.demo_expires_at);
    const today = moment();
    const daysInTrial = demoExpiresAt.diff(today, 'days');
    if (daysInTrial <= 0) {
      demo = (
        <Badge color="warning">
          Trial Expired
        </Badge>
      );
    } else {
      demo = (
        <Badge color="primary">
          Trial (
          {daysInTrial}
          {' days left)'}
        </Badge>
      );
    }
  }

  return (
    <tr onClick={onClick} style={{ cursor: 'pointer' }}>
      <td>
        <div className="d-flex flex-row align-items-center">
          <Image user={user} />
          {' '}
          <div className="ml-2 user-details">
            <h5>
              {user.name}
              {demo}
            </h5>
            <h6>{user.email}</h6>
          </div>
        </div>
      </td>
      <td className="user-activity">
        <div>
          Last Seen:
          {' '}
          <UserDate date={user.current_sign_in_at} />
        </div>
        <div>
          Total Visits:
          {' '}
          {user.sign_in_count}
        </div>
      </td>
      {/* <td>
        <Progress userID={user.id} accountMetrics={user.account_metrics} />
      </td> */}
      <td>
        <span
          id={`user-${user.id}-status`}
          style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
        >
          {status}
        </span>
        <UncontrolledTooltip placement="top" target={`user-${user.id}-status`} boundariesElement="window">
          {status === 'Inactive' && (
            "We haven't seen this user in over a month"
          )}
          {status === 'WAU' && (
            'This user has been seen in the last week (Weekly Active User)'
          )}
          {status === 'MAU' && (
            'This user has been seen in the last monthly (Monthly Active User)'
          )}
        </UncontrolledTooltip>
      </td>
    </tr>
  );
};

UserListItem.propTypes = {
  navigate: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    demo_expires_at: PropTypes.string,
    demo_account: PropTypes.bool,
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    cell_phone: PropTypes.string,
    current_sign_in_at: PropTypes.string,
    weekly_active_user: PropTypes.bool,
    monthly_active_user: PropTypes.bool,
    inactive_user: PropTypes.bool,
    sign_in_count: PropTypes.number,
    // account_metrics: PropTypes.shape({
    //   progress: PropTypes.number,
    // }).isRequired,
  }).isRequired,
};

export default UserListItem;
