export function removeSingleItemByIndex(array, index) {
  if (index < 0 || index >= array.length) return array;
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ];
}
export function updateSingleItemByIndex(array, index, item) {
  if (index < 0 || index >= array.length) return array;

  return [
    ...array.slice(0, index),
    item,
    ...array.slice(index + 1),
  ];
}
export function removeSingleItemById(array, id) {
  if (!id) return array;

  const index = array.findIndex(i => i.id === id);
  return removeSingleItemByIndex(array, index);
}

export function updateSingleItemById(array, item) {
  const index = array.findIndex(i => i.id === item.id);
  return updateSingleItemByIndex(array, index, item);
}
