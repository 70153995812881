import { push } from 'connected-react-router';
import { call, put, take } from 'redux-saga/effects';

import {
  REQUEST_DEALERSHIP_DEALER_USER_UPDATE,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
  DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS,
  REQUEST_DEALERSHIP_DEALER_USER_UPDATE_SUCCESS,
  REQUEST_DEALERSHIP_DEALER_USER_UPDATE_FAILURE,
  DEALERSHIP_DEALER_USER_UPDATING,
} from '../../constants/dealership_dealer_users';

import Api from '../../utilities/Api';

export function* updateDealerUser(dealerUser = {}) {
  yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS });
  if (dealerUser === {}) return;
  try {
    yield put({ type: DEALERSHIP_DEALER_USER_UPDATING });
    const response = yield call(
      Api.putAuthenticated,
      `/consultants/${dealerUser.id}`,
      { ...dealerUser },
    );
    yield put({
      type: REQUEST_DEALERSHIP_DEALER_USER_UPDATE_SUCCESS,
      payload: response.data,
    });
    yield put(push('/dealership'));
  } catch (error) {
    yield put({ type: REQUEST_DEALERSHIP_DEALER_USER_UPDATE_FAILURE });
    if (error.response && error.response.data) {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: error.response.data.error });
    } else {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: 'Something went wrong. Please try again' });
    }
  }
}

export default function* watchForUpdateDealershipDealerUser() {
  while (true) {
    const action = yield take(REQUEST_DEALERSHIP_DEALER_USER_UPDATE);
    yield call(updateDealerUser, action.payload);
  }
}
