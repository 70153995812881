import { call, put, take } from 'redux-saga/effects';

import Api from '../../utilities/Api';

import {
  REQUEST_DEALERSHIP_FETCH,
  REQUEST_DEALERSHIP_FETCH_SUCCESS,
} from '../../constants/dealership';
import { BEGIN_FETCH, END_FETCH } from '../../constants/status';

function* fetchDealership() {
  try {
    yield put({ type: BEGIN_FETCH });
    const response = yield call(Api.getAuthenticated, '/dealership');
    yield put({ type: REQUEST_DEALERSHIP_FETCH_SUCCESS, payload: response.data });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  yield put({ type: END_FETCH });
}

export default function* watchForFetchDealership() {
  while (true) {
    yield take(REQUEST_DEALERSHIP_FETCH);
    yield call(fetchDealership);
  }
}
