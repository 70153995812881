import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import NewUser from './SubscriptionManagement/NewUser';
import DemoUser from './SubscriptionManagement/DemoUser';
import FullUser from './SubscriptionManagement/FullUser';

const SubscriptionManagement = ({ user, onChange, setUser, onChangeMulti }) => {
  let SubscriptionPanel = null;
  if (!user.id) {
    SubscriptionPanel = NewUser;
  } else if (user.demo_account) {
    SubscriptionPanel = DemoUser;
  } else {
    SubscriptionPanel = FullUser;
  }
  return (
    <Card className="shadow user--subscription-management mb-4">
      <CardBody>
        <h3>
          Subscription Management
        </h3>
        <div>
          <SubscriptionPanel
            user={user}
            onChange={onChange}
            setUser={setUser}
            onChangeMulti={onChangeMulti}
          />
        </div>
      </CardBody>
    </Card>
  );
};

SubscriptionManagement.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    demo_account: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  setUser: PropTypes.func.isRequired,
  onChangeMulti: PropTypes.func.isRequired,
};

SubscriptionManagement.defaultProps = {
  onChange: () => null,
};

export default SubscriptionManagement;
