import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import dealerUser from './dealer_user';
import login from './login';
import status from './status';
import users from './users';
import dealership from './dealership';
import dealershipDealerUsers from './dealership_dealer_users';

export default history => combineReducers({
  router: connectRouter(history),
  dealerUser,
  login,
  status,
  users,
  dealership,
  dealershipDealerUsers,
});
