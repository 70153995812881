import {
  REQUEST_DEALERSHIP_DEALER_USERS_FETCH,
  DEALERSHIP_DEALER_USER_REQUEST_CREATE,
  DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS,
  REQUEST_DEALERSHIP_DEALER_USER_UPDATE,
  DEALERSHIP_DEALER_USER_UPDATING,
  DEALERSHIP_DEALER_USER_REQUEST_DELETE,
  DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION,
  REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH,
} from '../constants/dealership_dealer_users';

// eslint-disable-next-line import/prefer-default-export
export function fetchDealershipDealerUsers() {
  return { type: REQUEST_DEALERSHIP_DEALER_USERS_FETCH };
}

export function fetchSingleDealershipDealerUser(id) {
  return { type: REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH, id };
}

export function reqestCreateDealershipDealerUser(obj) {
  return { type: DEALERSHIP_DEALER_USER_REQUEST_CREATE, payload: obj };
}
export function resetDealershipDealerUserErrors() {
  return { type: DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS };
}

export function updateDealershipDealerUser(obj) {
  return { type: REQUEST_DEALERSHIP_DEALER_USER_UPDATE, payload: obj };
}

export function dealershipDealerUserUpdating() {
  return { type: DEALERSHIP_DEALER_USER_UPDATING };
}

export function requestDealershipDeleteDealerUser(id) {
  return { type: DEALERSHIP_DEALER_USER_REQUEST_DELETE, id };
}

export function toggleDealershipDealerUserPermission(id) {
  return { type: DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION, id };
}
