export function sortBy(field, reverse) {
  const key = x => x[field];

  const reverseValue = !reverse ? 1 : -1;

  return (a, b) => reverseValue * ((key(a) > key(b)) - (key(b) > key(a)));
}

export function orderBy(array, key, order) {
  const newArray = [...array];
  return newArray.sort(sortBy(key, order === 'desc'));
}
