import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button, Alert } from 'reactstrap';

import ContactInformation from '../../DealershipUserNew/ContactInformation';
import PermissionOptions from '../../DealershipUserNew/PermissionOptions';

class DealerUserUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerUserObject: {
        id: this.props.dealer_user.id,
        admin_access: this.props.dealer_user.admin_access,
        first_name: this.props.dealer_user.first_name,
        last_name: this.props.dealer_user.last_name,
        phone: this.props.dealer_user.phone,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.props.resetDealershipDealerUserErrors();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updating !== prevProps.updating) {
      if (!this.props.updating) {
        this.props.toggleModal(false);
      }
    }
  }

  onChange(value, attr) {
    // eslint-disable-next-line max-len
    this.setState(prevState => ({ dealerUserObject: { ...prevState.dealerUserObject, [attr]: value } }));
  }

  onSave(event) {
    event.preventDefault();
    this.props.updateDealershipDealerUser(this.state.dealerUserObject);
  }

  render() {
    const { dealerUserObject } = this.state;

    return (
      <section className="user-form">
        <h1>
          {`Update ${this.props.dealer_user.display_name}`}
        </h1>
        { this.props.error
          && (
          <Alert color="warning" className="user-form--error">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {' '}
            { this.props.error }
          </Alert>
          ) }
        <form onSubmit={this.onSave}>
          <ContactInformation formObject={dealerUserObject} onChange={this.onChange} newOrUpdate="update" />
          <PermissionOptions formObject={dealerUserObject} onChange={this.onChange} />
          <div className="float-left">
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faEnvelopeOpenText} className="mr-2" />
              <b>Update</b>
              {' '}
              Dealer User
            </Button>
            <Button className="btn btn-secondary ml-3" onClick={() => this.props.toggleModal(false)}>
              Cancel
            </Button>
          </div>
        </form>
      </section>
    );
  }
}

DealerUserUpdate.propTypes = {
  dealer_user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    admin_access: PropTypes.bool.isRequired,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
  updateDealershipDealerUser: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  resetDealershipDealerUserErrors: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default DealerUserUpdate;
