import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import Users from '../components/Users';
import { getUsers } from '../reducers/users';

const mapDispatchToProps = {
  navigate: push,
};

function mapStateToProps(state) {
  return {
    dealer_user: state.dealerUser.dealer_user,
    users: getUsers(state).filter(user => !user.dealer_demonstration),
    fetching: state.status.fetching > 0,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
