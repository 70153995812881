import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBan, faTrash, faEdit, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalHeader, Button, Badge } from 'reactstrap';

import DealerUserUpdate from '../../../containers/Dealership/DealerUserUpdate';
import DealerUserPermissions from './DealerUserPermissions/DealerUserPermissions';

// eslint-disable-next-line max-len
const DealerUserListItem = ({ dealerUser, currentDealerUser, requestDeleteDealerUser, fetchSingleDealershipDealerUser }) => {
  const [open, setOpen] = useState(false);
  const [updateFormOpen, setUpdateFormOpen] = useState(false);
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);

  function deleteDealerUser(id) {
    requestDeleteDealerUser(id);
    setOpen(false);
  }

  function closePermissionsModal(bool) {
    const { id } = dealerUser;
    setPermissionsModalOpen(bool);
    if (!bool) {
      fetchSingleDealershipDealerUser(id);
    }
  }

  return (
    <>
      <tr>
        <td>
          <div className="d-flex flex-row align-items-center">
            <div className="ml-2 user-details">
              <h5>
                {dealerUser.display_name}
              </h5>
              <h6>{dealerUser.email}</h6>
            </div>
          </div>
        </td>
        <td className="user-activity">
          <div className="d-flex flex-row align-items-center">
            <div className="ml-2 user-details">
              <h5>
                {dealerUser.sign_in_access_text}
              </h5>
            </div>
          </div>
        </td>
        <td className="user-activity">
          <div className="d-flex flex-row align-items-center">
            <div className="ml-2 user-details">
              <h5>
                <FontAwesomeIcon icon={dealerUser.admin_access ? faCheckCircle : faBan} className="mr-1" />
                {dealerUser.admin_access ? 'Has Access' : 'No Access'}
              </h5>
            </div>
          </div>
        </td>
        <td className="user-activity">
          <div className="d-flex flex-row align-items-center">
            <div className="ml-2 user-details">
              <h5>
                {currentDealerUser.id !== dealerUser.id && !dealerUser.main_account && (
                  <>
                    <Button className="shadow-sm" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                      />
                    </Button>
                    <Button className="ml-3 shadow-sm" onClick={() => setUpdateFormOpen(true)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                      />
                    </Button>
                    <Button className="ml-3 shadow-sm" onClick={() => closePermissionsModal(true)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon
                        icon={faUsers}
                      />
                    </Button>
                  </>
                )}
                {currentDealerUser.id === dealerUser.id && (
                  <Badge bg="secondary">Your Account</Badge>
                )}
                {currentDealerUser.id !== dealerUser.id && dealerUser.main_account && (
                  <Badge bg="secondary">Main Account</Badge>
                )}
              </h5>
            </div>
          </div>
        </td>
      </tr>
      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalHeader>
          {`Do you really want to delete ${dealerUser.display_name}?`}
        </ModalHeader>
        <ModalBody>
          Deleting the dealer user removes them from the dealership completely.
          Their account will be deleted from our records.
          <div className="mt-3">
            <Button color="secondary" className="mr-2" onClick={() => setOpen(false)}>
              <b>Cancel</b>
            </Button>
            <Button color="danger" onClick={() => deleteDealerUser(dealerUser.id)}>
              <b>Delete</b>
              {' '}
              from dealership
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={updateFormOpen}
        toggle={() => setUpdateFormOpen(false)}
        style={{ maxWidth: 700 }}
      >
        <ModalBody>
          <DealerUserUpdate
            dealer_user={dealerUser}
            toggleModal={setUpdateFormOpen}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={permissionsModalOpen}
        toggle={() => closePermissionsModal(false)}
        style={{ maxWidth: 700 }}
      >
        <ModalBody>
          <DealerUserPermissions
            dealer_user={dealerUser}
            toggleModal={closePermissionsModal}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

DealerUserListItem.propTypes = {
  currentDealerUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  dealerUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    admin_access: PropTypes.bool.isRequired,
    main_account: PropTypes.bool.isRequired,
    sign_in_access_text: PropTypes.string.isRequired,
  }).isRequired,
  requestDeleteDealerUser: PropTypes.func.isRequired,
  fetchSingleDealershipDealerUser: PropTypes.func.isRequired,
};

export default DealerUserListItem;
