import {
  REQUEST_DEALER_USER_FETCH_SUCCESS,
  REQUEST_DEALER_USER_RESET,
  DEALER_USER_REQUEST_ERROR,
  DEALER_USER_REQUEST_RESET_ERRORS,
} from '../constants/dealer_user';

export const initialState = { error: '', dealer_user: {}, updating: false };

export function getDealerUser(state) {
  return state.dealerUser.dealer_user;
}

export default function dealerUserReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DEALER_USER_FETCH_SUCCESS:
      return {
        ...state,
        dealer_user: action.payload,
      };
    case DEALER_USER_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DEALER_USER_REQUEST_RESET_ERRORS:
      return {
        ...state,
        error: '',
      };
    case REQUEST_DEALER_USER_RESET:
      return initialState;
    default:
      return state;
  }
}
