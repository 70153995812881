import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';


const SubscriptionManagementFullUser = ({ user }) => {
  const signedUpOn = moment(user.software_sign_up_date);

  return (
    <div>
      <b>Paid Account</b>
      <p>
        This user started using a full account on
        {' '}
        <b><time>{signedUpOn.format('MMMM D, YYYY')}</time></b>
      </p>
    </div>
  );
};

SubscriptionManagementFullUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    software_sign_up_date: PropTypes.string,
  }).isRequired,
};

export default SubscriptionManagementFullUser;
