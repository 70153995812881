import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button, Alert } from 'reactstrap';
import { faLongArrowLeft } from '../shared/CustomIcons';

import ContactInformation from './DealershipUserNew/ContactInformation';
import PermissionOptions from './DealershipUserNew/PermissionOptions';

class DealershipUserNew extends Component {
  constructor(props) {
    super(props);
    this.state = { dealerUserObject: { admin_access: true } };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    // reset errors
    this.props.resetDealershipDealerUserErrors();
  }

  onChange(value, attr) {
    // eslint-disable-next-line max-len
    this.setState(prevState => ({ dealerUserObject: { ...prevState.dealerUserObject, [attr]: value } }));
  }

  onSave(event) {
    event.preventDefault();
    this.props.reqestCreateDealershipDealerUser(this.state.dealerUserObject);
  }

  render() {
    const { dealerUserObject } = this.state;

    if (!this.props.dealer_user.admin_access) {
      return null;
    }

    return (
      <section className="user-form">
        <NavLink exact to="/dealership" className="d-block mb-4">
          <FontAwesomeIcon icon={faLongArrowLeft} />
          {' '}
          Return to dealership
        </NavLink>
        { this.props.error
          && (
          <Alert color="warning" className="user-form--error">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {' '}
            {this.props.error}
          </Alert>
          ) }
        <h1>
          New Dealer User
        </h1>
        <form onSubmit={this.onSave}>
          <ContactInformation formObject={dealerUserObject} onChange={this.onChange} newOrUpdate="new" />
          <PermissionOptions formObject={dealerUserObject} onChange={this.onChange} />
          <div className="float-left">
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faEnvelopeOpenText} className="mr-2" />
              <b>Send</b>
              {' '}
              invite to Dealership
            </Button>
            <NavLink exact to="/dealership" className="btn btn-secondary ml-3">
              Cancel
            </NavLink>
          </div>
        </form>
      </section>
    );
  }
}

DealershipUserNew.propTypes = {
  dealer_user: PropTypes.shape({
    admin_access: PropTypes.bool.isRequired,
  }).isRequired,
  reqestCreateDealershipDealerUser: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  resetDealershipDealerUserErrors: PropTypes.func.isRequired,
};

export default DealershipUserNew;
