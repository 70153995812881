import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import NotFound from '../../components/NotFound';
import Navigation from '../../containers/Navigation';

import Dealership from '../../containers/Dealership';
import Demonstrations from '../../containers/Demonstrations';
import Demonstration from '../../containers/Demonstrations/Demonstration';
import DemonstrationNew from '../../containers/Demonstrations/DemonstrationNew';
import DemonstrationEdit from '../../containers/Demonstrations/DemonstrationEdit';
import DealershipUserNew from '../../containers/Dealership/DealershipUserNew';
import User from '../../containers/User';
import UserNew from '../../containers/UserNew';
import UserEdit from '../../containers/UserEdit';
import Users from '../../containers/Users';
import PasswordManagement from '../../containers/PasswordManagement';
/**
 * Renders all the routes used in the app.
 * Be sure to use functional components and the `render` method if possible, instead of a component
 * https://reacttraining.com/react-router/web/api/Route/component
 */
const AuthenticatedRoutes = () => (
  <>
    <Route component={Navigation} />
    <main>
      <Switch>
        <Redirect from="/login" to="/" />
        <Route exact path="/change-password" component={PasswordManagement} />
        <Route exact path="/dealership" component={Dealership} />
        <Route exact path="/dealership/invite-dealer-user" component={DealershipUserNew} />
        <Route exact path="/demonstrations" component={Demonstrations} />
        <Route exact path="/demonstrations/new" component={DemonstrationNew} />
        <Route exact path="/demonstrations/:id/edit" component={DemonstrationEdit} />
        <Route path="/demonstrations/:id" component={Demonstration} />
        <Route exact path="/users/new" component={UserNew} />
        <Route exact path="/users/:id/edit" component={UserEdit} />
        <Route path="/users/:id" component={User} />
        <Route exact path="/" component={Users} />
        <Route component={NotFound} />
      </Switch>
    </main>
  </>
);

export default AuthenticatedRoutes;
