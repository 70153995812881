import _ from 'lodash';
import { take, fork, call, put } from 'redux-saga/effects';

import {
  REQUEST_USERS_FETCH,
  REQUEST_USERS_FETCH_SUCCESS,
  REQUEST_USERS_FETCH_FAILURE,
  REQUEST_USERS_RESET,
} from '../../constants/users';
import {
  BEGIN_FETCH,
  END_FETCH,
  END_INITIAL_LOAD,
} from '../../constants/status';
import Api from '../../utilities/Api';

/**
 * Fetches Users for the given page number. Hits the API, and dispatches
 * additional redux actions based on the response.
 */
function* fetchUsers() {
  try {
    yield put({ type: BEGIN_FETCH });
    yield put({ type: REQUEST_USERS_RESET });
    const response = yield call(Api.getAuthenticated, '/users', { page: 1 });
    const total = yield _.toNumber(response.headers.total);
    const perPage = yield _.toNumber(response.headers['per-page']);
    const pages = yield Math.ceil(total / perPage);

    yield put({ type: REQUEST_USERS_FETCH_SUCCESS, payload: response.data });

    for (let page = 2; page <= pages; page += 1) {
      const r = yield call(Api.getAuthenticated, '/users', { page });
      yield put({ type: REQUEST_USERS_FETCH_SUCCESS, payload: r.data });
    }
  } catch (error) {
    yield put({ type: REQUEST_USERS_FETCH_FAILURE, payload: error.message });
  }
  yield put({ type: END_FETCH });
  yield put({ type: END_INITIAL_LOAD });
}

export default function* watchForFetchUsers() {
  while (true) {
    yield take(REQUEST_USERS_FETCH);
    yield fork(fetchUsers);
  }
}
