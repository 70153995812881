import {
  BEGIN_FETCH,
  END_FETCH,
  END_INITIAL_LOAD,
} from '../constants/status';

export const initialState = {
  initial_fetch: true,
  fetching: 0,
};

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case BEGIN_FETCH:
      return {
        ...state,
        fetching: state.fetching + 1,
      };
    case END_FETCH:
      return {
        ...state,
        fetching: state.fetching - 1,
      };
    case END_INITIAL_LOAD:
      return {
        ...state,
        initial_fetch: false,
      };
    default:
      return state;
  }
}
