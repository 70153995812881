import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card } from 'reactstrap';
import { faLongArrowLeft } from './shared/CustomIcons';

import DealershipContactInfo from './Dealership/DealershipContactInfo';
import DealershipUsersList from './Dealership/DealershipUsersList';

class Dealership extends Component {
  constructor() {
    super();
    this.state = { open: false };
  }

  componentDidMount() {
    if (isEmpty(this.props.dealership)) {
      this.props.fetchDealership();
    }
    if (isEmpty(this.props.dealershipDealerUsers)) {
      this.props.fetchDealershipDealerUsers();
    }
  }

  setOpen() {
    this.setState(prevstate => ({ open: !prevstate.open }));
  }

  sortedDealerUsers() {
    const { dealershipDealerUsers } = this.props;
    const dealerUser = this.props.dealer_user;

    if (dealershipDealerUsers.length === 1) {
      return dealershipDealerUsers;
    }

    const mainDealerUserAccount = dealershipDealerUsers.filter(du => du.main_account)[0];
    let nonMainAccountDealerUsers = dealershipDealerUsers.filter(du => !du.main_account);

    if (dealerUser.main_account) {
      return [mainDealerUserAccount, ...nonMainAccountDealerUsers];
    }

    const yourAccount = nonMainAccountDealerUsers.filter(du => du.id === dealerUser.id)[0];
    nonMainAccountDealerUsers = nonMainAccountDealerUsers.filter(du => du.id !== yourAccount.id);
    return [mainDealerUserAccount, yourAccount, ...nonMainAccountDealerUsers];
  }

  render() {
    if (!this.props.dealer_user.admin_access) {
      return null;
    }

    if (this.props.fetching && isEmpty(this.props.dealershipDealerUsers)) {
      return (
        <div style={{ minHeight: '60vh', opacity: 0.6 }} className="d-flex flex-column align-items-center justify-content-center">
          <div style={{ fontSize: 50 }}>
            <FontAwesomeIcon pulse icon={faSpinner} />
          </div>
          Fetching Dealership...
        </div>
      );
    }

    return (
      <div className="user">
        <NavLink exact to="/" className="d-block mb-4">
          <FontAwesomeIcon icon={faLongArrowLeft} />
          {' '}
          Return to all users
        </NavLink>
        <Row noGutters className="justify-content-between mb-3">
          <Col xs="12" md="auto">
            <h1>{this.props.dealership.company_name || 'No Dealership Name'}</h1>
            <h3 className="user--email">{this.props.dealership.email}</h3>
          </Col>
        </Row>
        <DealershipContactInfo dealership={this.props.dealership} />
        <Row noGutters className="justify-content-between mb-3 mt-5">
          <Col xs="12" md="auto">
            <h1>Dealer Users</h1>
          </Col>
          <Col xs="12" md="auto" className="mt-3 mt-md-0 d-flex flex-row align-items-center">
            <NavLink className="btn btn-primary border-0" exact to="/dealership/invite-dealer-user">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              <b>Add</b>
              {' '}
              Dealer User
            </NavLink>
          </Col>
        </Row>
        <Card>
          <DealershipUsersList
            dealerUsers={this.sortedDealerUsers()}
            requestDeleteDealerUser={this.props.requestDealershipDeleteDealerUser}
            currentDealerUser={this.props.dealer_user}
            fetchSingleDealershipDealerUser={this.props.fetchSingleDealershipDealerUser}
          />
        </Card>
      </div>
    );
  }
}

Dealership.propTypes = {
  dealership: PropTypes.shape({
    email: PropTypes.string,
    company_name: PropTypes.string,
  }).isRequired,
  dealershipDealerUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
  })).isRequired,
  fetching: PropTypes.bool,
  dealer_user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    admin_access: PropTypes.bool.isRequired,
    main_account: PropTypes.bool.isRequired,
  }).isRequired,
  fetchDealership: PropTypes.func.isRequired,
  fetchDealershipDealerUsers: PropTypes.func.isRequired,
  requestDealershipDeleteDealerUser: PropTypes.func.isRequired,
  fetchSingleDealershipDealerUser: PropTypes.func.isRequired,
};

Dealership.defaultProps = {
  fetching: false,
};

export default Dealership;
