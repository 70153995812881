import React from 'react';
import { Col } from 'reactstrap';

const App = () => (
  <Col xs="9" className="text-center pv-3">
    <p>
      Nothing was found.
    </p>
  </Col>
);

export default App;
