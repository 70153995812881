import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { faLongArrowLeft, faPencil } from '../shared/CustomIcons';
import LoginAsUser from '../../containers/User/LoginAsUser';
import DemonstrationNotFound from './DemonstrationNotFound';

class Demonstration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { demonstration } = this.props;

    if (isEmpty(demonstration)) return <DemonstrationNotFound />;

    return (
      <section className="user">
        <NavLink exact to="/demonstrations" className="d-block mb-4">
          <FontAwesomeIcon icon={faLongArrowLeft} />
          {' '}
          Return to all Demonstrations
        </NavLink>
        <div className="card p-3">
          <Row noGutters className="justify-content-between">
            <Col xs="12" md="auto">
              <h1>{demonstration.name || 'No Name'}</h1>
            </Col>
            <Col xs="12" md="auto" className="mt-3 mt-md-0 d-flex flex-row align-items-center">
              <LoginAsUser user={demonstration} />
              <NavLink className="btn btn-primary border-0" exact to={`/demonstrations/${demonstration.id}/edit`}>
                <FontAwesomeIcon icon={faPencil} className="mr-2" />
                <b>Edit</b>
                {' '}
                Demonstration
              </NavLink>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

Demonstration.propTypes = {
  demonstration: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    cell_phone: PropTypes.string,
    current_sign_in_at: PropTypes.string,
    weekly_active_user: PropTypes.bool,
    monthly_active_user: PropTypes.bool,
    inactive_user: PropTypes.bool,
    account_metrics: PropTypes.shape({}),
    consultant_has_sign_in_access: PropTypes.bool,
  }),
};

Demonstration.defaultProps = {
  demonstration: {},
};

export default Demonstration;
