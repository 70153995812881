import { pick } from 'lodash';
import Api from '../../utilities/Api';

const attributes = [
  'name',
  'cell_phone',
  'street_address',
  'city',
  'state',
  'zip',
  'country_id',
];

const createAttributes = [
  'email',
  'demo_account',
  'dealer_demonstration',
];

export async function requestUpdate(formObject) {
  const requestObject = pick(formObject, attributes);
  return Api.putAuthenticated(`/users/${formObject.id}`, requestObject);
}

export async function requestCreate(formObject) {
  const requestObject = pick(formObject, [...attributes, ...createAttributes]);
  return Api.postAuthenticated('/users/', requestObject);
}

export async function requestUpgradeAccount(id) {
  return Api.putAuthenticated(`/users/${id}`, { request_demo_upgrade: true });
}
