import {
  REQUEST_DEALERSHIP_FETCH_SUCCESS,
  REQUEST_DEALERSHIP_RESET,
} from '../constants/dealership';

export const dealerInitialState = { error: '', dealership: {} };

export function getDealership(state) {
  return state.dealership.dealership;
}

export default function dealershipReducer(state = dealerInitialState, action) {
  switch (action.type) {
    case REQUEST_DEALERSHIP_FETCH_SUCCESS:
      return {
        ...state,
        dealership: action.payload,
      };
    case REQUEST_DEALERSHIP_RESET:
      return dealerInitialState;
    default:
      return state;
  }
}
