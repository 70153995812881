import {
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN_FAILURE,
} from '../constants/login';

export const initialState = {
  message: '',
  count: 0,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LOGIN_SUCCESS:
      return initialState;
    case REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        message: action.payload,
        count: state.count + 1,
      };
    default:
      return state;
  }
}
