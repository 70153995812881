import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserFilters = (props) => {
  if (props.user && !props.user.consultant_has_sign_in_access) {
    return null;
  }

  const loginAsUser = () => props.loginAsUser(props.user.id);
  return (
    <Button className="mr-3" color="secondary" onClick={loginAsUser}>
      <FontAwesomeIcon icon={faShare} className="mr-2" />
      <b>{props.user.dealer_demonstration ? 'Enter' : 'Login'}</b>
      {' '}
      {props.user.dealer_demonstration ? 'Demonstration' : 'As User'}
    </Button>
  );
};

UserFilters.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    consultant_has_sign_in_access: PropTypes.bool,
    dealer_demonstration: PropTypes.bool,
  }).isRequired,
  loginAsUser: PropTypes.func.isRequired,
};

export default UserFilters;
