import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import Routes from './routes';
import store from './store';

import './styles/index.scss';

Sentry.init({
  dsn: 'https://ff0735aa91734759bce52d83d02bdec7@sentry.io/1326319',
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

export default class App extends Component {
  // Report errors to sentry in production, and in the console in development
  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
