import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { faArrowUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestUpgradeAccount } from '../FormApiRequests';


const SubscriptionManagementDemoUser = ({ user, onChange, setUser }) => {
  const [upgrading, setUpgrading] = useState(false);
  async function onRequestUpgrade(event) {
    event.preventDefault();
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to upgrade this account?')) {
        setUpgrading(true);
        const responseObject = await requestUpgradeAccount(user.id);
        setUser(responseObject.data);
        onChange(false, 'demo_account');
        setUpgrading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  const demoExpiresAt = moment(user.demo_expires_at);
  const today = moment();
  const daysInTrial = demoExpiresAt.diff(today, 'days');

  return (
    <div>
      {daysInTrial <= 0 ? (
        <p>
          This user&apos;s demo expired on
          {' '}
          <b><time>{demoExpiresAt.format('MMMM D, YYYY')}</time></b>
        </p>
      ) : (
        <p>

          This user is currently demoing the software. Their free trial ends in
          {' '}
          <b>
            {daysInTrial}
            {' '}
            days.
          </b>
        </p>
      )}

      <Button color="primary" outline onClick={onRequestUpgrade} disabled={upgrading}>
        {upgrading ? (
          <>
            <FontAwesomeIcon icon={faSpinner} pulse className="mr-2" />
            Upgrading account...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faArrowUp} className="mr-2" />
            <b>Upgrade</b>
            {' to full account'}
          </>
        )}
      </Button>
      <small>* You will be billed for this user’s subscription</small>
    </div>
  );
};

SubscriptionManagementDemoUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    demo_account: PropTypes.bool,
    demo_expires_at: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SubscriptionManagementDemoUser;
