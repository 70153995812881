import { call, put, take } from 'redux-saga/effects';
import Api from '../../utilities/Api';

import {
  DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION,
  DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION_SUCCESS,
  DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS,
  DEALERSHIP_DEALER_USER_REQUEST_ERROR,
} from '../../constants/dealership_dealer_users';

export function* toggleDealerUserPermisison(id) {
  yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS });
  try {
    yield call(
      Api.postAuthenticated,
      `/dealer_user_permissions/${id}/toggle`,
    );
    yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION_SUCCESS });
  } catch (error) {
    if (error.response && error.response.data) {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: error.response.data.error });
    } else {
      yield put({ type: DEALERSHIP_DEALER_USER_REQUEST_ERROR, payload: 'Something went wrong. Please try again' });
    }
  }
}

export default function* watchForUpdateDealershipDealerUserPermisisons() {
  while (true) {
    const action = yield take(DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION);
    yield call(toggleDealerUserPermisison, action.id);
  }
}
