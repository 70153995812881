import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Table } from 'reactstrap';

import UserListHeader from './UserList/UserListHeader';
import UserListItem from './UserList/UserListItem';
import { orderBy } from '../../utilities/application_helper';

const UserList = (props) => {
  const { navigate, users } = props;
  const [sortBy, setSortBy] = useState({ activity: 'desc' });

  function changeSortBy(sortKey) {
    if (!sortBy[sortKey]) {
      setSortBy({ [sortKey]: 'asc' });
    } else if (sortBy[sortKey] === 'asc') {
      setSortBy({ [sortKey]: 'desc' });
    } else {
      setSortBy({ [sortKey]: 'asc' });
    }
  }

  function userSortStatus(user) {
    if (user.weekly_active_user) return 0;
    if (user.monthly_active_user) return 1;
    return 2;
  }

  const sortableUsers = users.map(user => ({
    ...user,
    activity: new Date(user.current_sign_in_at || '1990-01-01'),
    // progress: user.account_metrics.progress,
    status: userSortStatus(user),
  }));

  const orderedUsers = orderBy(sortableUsers, Object.keys(sortBy)[0], Object.values(sortBy)[0]);

  return (
    <Table hover responsive>
      <thead className="card-header">
        <UserListHeader onChangeSortBy={changeSortBy} sortBy={sortBy} />
      </thead>
      <tbody className="card-body">
        {orderedUsers.map(user => <UserListItem key={user.id} user={user} navigate={navigate} />)}
      </tbody>
    </Table>
  );
};

UserList.propTypes = {
  navigate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserList;
