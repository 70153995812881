import { connect } from 'react-redux';
import Demonstration from '../../components/Demonstrations/Demonstration';
import { getUserByID } from '../../reducers/users';
import { requestSingleUser } from '../../actions/users';

const mapDispatchToProps = {
  requestDemonstration: requestSingleUser,
};

function mapStateToProps(state, props) {
  const demonstrationId = parseInt(props.match.params.id, 10);

  return {
    dealer_user: state.dealerUser.dealer_user,
    demonstration: getUserByID(state, demonstrationId),
    fetching: state.status.fetching > 0,
    demonstrationId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Demonstration);
