// FETCHING
export const REQUEST_DEALERSHIP_DEALER_USERS_FETCH = 'REQUEST_DEALERSHIP_DEALER_USERS_FETCH';
export const REQUEST_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS = 'REQUEST_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS';
export const REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH = 'REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH';
export const REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS = 'REQUEST_SINGLE_DEALERSHIP_DEALER_USERS_FETCH_SUCCESS';

// CREATING
export const DEALERSHIP_DEALER_USER_REQUEST_CREATE = 'DEALERSHIP_DEALER_USER_REQUEST_CREATE';
export const DEALERSHIP_DEALER_USER_CREATED = 'DEALERSHIP_DEALER_USER_CREATED';

// UPDATING
export const REQUEST_DEALERSHIP_DEALER_USER_UPDATE = 'REQUEST_DEALERSHIP_DEALER_USER_UPDATE';
export const REQUEST_DEALERSHIP_DEALER_USER_UPDATE_SUCCESS = 'REQUEST_DEALERSHIP_DEALER_USER_UPDATE_SUCCESS';
export const REQUEST_DEALERSHIP_DEALER_USER_UPDATE_FAILURE = 'REQUEST_DEALERSHIP_DEALER_USER_UPDATE_FAILURE';
export const DEALERSHIP_DEALER_USER_UPDATING = 'DEALER_USER_UPDATING';

// TOGGLING PERMISSIONS
export const DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION = 'DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION';
export const DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION_SUCCESS = 'DEALERSHIP_DEALER_USER_REQUEST_TOGGLE_PERMISSION_SUCCESS';

// DELETING
export const DEALERSHIP_DEALER_USER_REQUEST_DELETE = 'DEALERSHIP_DEALER_USER_REQUEST_DELETE';
export const DEALERSHIP_DEALER_USER_REQUEST_DELETE_SUCCESS = 'DEALER_USER_REQUEST_DELETE_SUCCESS';

export const DEALERSHIP_DEALER_USER_REQUEST_ERROR = 'DEALERSHIP_DEALER_USER_REQUEST_ERROR';
export const DEALERSHIP_DEALER_USER_REQUEST_RESET_ERRORS = 'DEALER_USER_REQUEST_RESET_ERRORS';
