import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { logout } from '../actions/logout';
import Navigation from '../components/Navigation';

const mapDispatchToProps = {
  logout,
  navigate: push,
};

function mapStateToProps(state) {
  return {
    dealer_user: state.dealerUser.dealer_user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
